import i18n from 'i18next'
import { toast } from 'react-toastify'
import { API_ERROR } from '../constants/ApiErrors'
import { CONSTANTS } from '../constants/ConstantValues'
import countriesLocales from '../constants/countries-locales.json'

const i18Translation = (locizeKey, options) => i18n.t(locizeKey, options)

/**
 *
 * @description Checks if browser storage is enabled on user's browser
 * @version 0.0.1
 */
const browserStorageEnabled = () => {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

/**
 * @usage
 * ```
 * generateRange(5,10)
 * ```
 * @param {number} start
 * @param {number} end
 *
 * @description Returns a number array from given range, including the start and
 * end
 */
const generateRange = (start, end) =>
  Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)

/**
 * @param {string} content  Content to be copied to clipboard
 * @param  {string} toastMessage  Toast messaged to be displayed when the user
 * copies the content to clipboard
 * @description Writes the provided content to user's clipboard
 */
const copyToClipboard = (content, toastMessage) => {
  navigator.clipboard
    .writeText(content)
    .then(() => toast.success(toastMessage))
    .catch(() => toast.error())
}

const millisecondsToSeconds = (milliseconds) =>
  milliseconds > 0 ? Math.floor(milliseconds / 1_000) : null

const secondsToMinutes = (seconds) =>
  seconds > 0 ? Math.floor(seconds / 60) : null

/**
 *
 * @param {string} dateOfBirth - Takes following format `2002-03-04`
 * @description For a given birth date returns the exact current age
 */
const getCurrentAge = (dateOfBirth) => {
  const dob = new Date(dateOfBirth)
  return Math.abs(new Date(Date.now() - dob.getTime()).getUTCFullYear() - 1970)
}

/**
 * @param {number} seconds
 * @description For provided time in seconds, converts the seconds into minute
 * and remaining seconds and returns an object `{minutes, remainingSeconds}`
 */
const toMinutesAndRemainingSeconds = (seconds) => {
  if (seconds > 0) {
    const minutes = secondsToMinutes(seconds)
    const remainingSeconds = seconds - minutes * 60
    return { minutes, remainingSeconds }
  }
  throw Error(
    `Provided argument: ${seconds} is negative number or not a number`
  )
}

/**
 *
 * @param {string} permission
 * @param {object} permissionOptions
 *
 * @description Asks for browser media permission like video or audio, takes in
 * `permission` string with a permission name, returns a callback `onSuccess` if
 * permission granted and `onFailure` if permission is denied
 *
 */
const askForBrowserPermission = (
  permission,
  { onSuccess, onFailure },
  permissionOptions
) => {
  const userMedia = Object.freeze({
    video: {
      video: permissionOptions ? permissionOptions : true,
    },
  })

  if (userMedia[permission]) {
    navigator.mediaDevices
      .getUserMedia(userMedia[permission])
      .then((stream) => {
        //Stops the stream right away so it does not interfere with biometrics,
        //there is no other solution for this
        stream.getTracks().forEach((track) => track.stop())
        onSuccess(true)
      })
      .catch(onFailure)

    return
  }
  throw Error(`Invalid argument: ${permission} does not exist `)
}

/**
 * @param {object} error - Error object returned from an API request
 * Generates an error message based from the API's error response
 * ID. The error response ID is then checked with the API_ERROR object that
 * contains locize keys. If an API error exists a locize translation is returned
 */
const generateApiError = (error = undefined) => {
  /**
   * @typedef {Object} ErrorResponse
   * @property {import('../constants/ApiErrors.types.js').BackendErrorId} id
   * @property {string} translatedError
   * @property {string} apiErrorMessage
   * @property {*} data
   */

  /**
   * Generates an error response.
   * @param {Object} param0
   * @returns {ErrorResponse}
   */
  const genErrorResponse = ({ id, translatedError, apiErrorMessage, data }) => {
    return {
      id,
      translatedError,
      apiErrorMessage,
      data,
    }
  }

  //error contains a response
  if (error?.response) {
    //destruct the response
    const {
      response: {
        //nested means that the API has responded with a main error and a nested
        //error which can be from another service for example banking service
        //has returned a banking service error and a user account nested error
        data: { id, message, nested, data },
      },
    } = error

    //Check if the response contains an error id
    if (API_ERROR[id]) {
      //Prioritize retuning nested error id and message

      return genErrorResponse({
        id,
        translatedError: i18Translation(API_ERROR[id]),
        apiErrorMessage: nested?.message || message,
        data,
      })
    }
  }

  return genErrorResponse({
    id: error?.code,
    translatedError: i18Translation(API_ERROR[error?.code] ?? 'ERROR_GENERIC'),
    apiErrorMessage: error?.message,
    data: error,
  })
}

/**
 * @param {string} sessionStorageKey - The key of the sessionStorage
 *
 * @description Returns a unique generated ID, if `sessionStorageKey` is passed
 * in then the generated key is stored in `sessionStorage`
 */
const generateUniqueId = (sessionStorageKey = undefined) => {
  const uniqueID =
    Math.random()
      .toString(CONSTANTS?.UNIQUE_ID_GENERATOR_RADIX)
      .substring(CONSTANTS?.DECIMAL_CUT_START, CONSTANTS?.DECIMAL_CUT_END) +
    Math.random()
      .toString(CONSTANTS?.UNIQUE_ID_GENERATOR_RADIX)
      .substring(CONSTANTS?.DECIMAL_CUT_START, CONSTANTS?.DECIMAL_CUT_END)

  if (browserStorageEnabled()) {
    if (sessionStorageKey) {
      sessionStorage.setItem(sessionStorageKey, uniqueID)
    }
  }

  return uniqueID
}

/**
 * @param {array} unsortedArray
 * @param {string} sortBy - Parameter to be sorted by, for example it can be
 * `name`,`earnings`
 * @param {any} order - By default it is sorted by descending order, if a value
 * passed in then it is sorted by ascending order
 *
 * @description Extended version of the `sort` function that takes in `sortBy`
 * parameter and sort order. The array is mutated and an reference is returned
 * to the same array.
 */
const sortArray = (unsortedArray, sortBy, order) => {
  if (sortBy) {
    if (order) {
      return unsortedArray?.sort((contribution, prevContribution) =>
        prevContribution[sortBy] < contribution[sortBy] ? 1 : -1
      )
    }
    return unsortedArray?.sort((contribution, prevContribution) =>
      prevContribution[sortBy] > contribution[sortBy] ? 1 : -1
    )
  }

  throw Error(`Sort by argument >>> ${sortBy} <<< not provided or invalid`)
}

/**
 * @param {array} arrayOfObjects  Objects to iterate trough
 * @param {string} fromDate  Parameter to be filtered by.
 * @param {string} to  Parameter to be filtered by.
 * @param {string} objectKey  Object key to be filtered by.
 *
 * @description Returns an array of objects filtered by date range, the
 * comparison is done in `string` format
 */
const filterByDateRange = (arrayOfObjects, objectKey, fromDate, toDate) => {
  if (!arrayOfObjects || !fromDate || !toDate || !objectKey) {
    throw new Error(
      `Missing or invalid arguments >>>${arrayOfObjects}<<< >>>${fromDate}<<< >>>${toDate}<<< >>>${objectKey}<<<`
    )
  }
  return arrayOfObjects?.filter((item) => {
    const date = item[objectKey]
    return date >= fromDate && date <= toDate
  })
}

/**
 * @param {string | number | Date} date Date in ISO8601 format or UnixTime
 * @param {object} formattingOptions Formatting options for
 * `formattedLocaleDate`
 * @param {string} locale Locale for the formatter function
 *
 * @description Converts a date to locale date string and locale time string,
 * formatting options can be passed in for formatting the date string
 */
const convertDateToClientLocale = (
  date,
  formattingOptions,
  locale = navigator.language
) => {
  const convertedDate = new Date(date)

  return {
    localeDate: convertedDate.toLocaleDateString(),
    localeTime: convertedDate.toLocaleTimeString(),
    formattedLocaleDate: convertedDate.toLocaleDateString(
      locale || CONSTANTS.FALLBACK_LOCALE,
      formattingOptions
    ),
  }
}

/**
 * @param {string} status Status string from `user_details`
 *
 * @description Returns the appropriate status text explanting the status to the
 * user
 */
const idVerificationStatusText = (status) => {
  if (CONSTANTS.APPROVED === status) {
    return i18Translation('ID_VERIFY_VERIFIED')
  }

  if (CONSTANTS.SUBMITTED === status) {
    return i18Translation('AUTH.CARD_ID_VERIFICATION_STATUS_BEING_VERIFIED')
  }

  if (CONSTANTS.REJECTED === status) {
    return i18Translation('ID_VERIFY_REJECTED')
  }

  return i18Translation('ID_VERIFY_SUBTITLE')
}
/**
 * Checks if user has completed L1 KYC
 */
const hasCompletedL1KYC = (user_details) => {
  const { phone_number, id_review_status, face_enrolled } = user_details

  return (
    Boolean(phone_number) &&
    face_enrolled &&
    id_review_status === CONSTANTS.APPROVED
  )
}

/**
 * @param {string} authType Authentication type from AuthMachine's context
 * `permissions`
 * @description Checks if the user has strong authentication type
 */
const hasStrongAuth = (authType) => {
  try {
    if (authType) {
      return authType === 'write'
    } else {
      throw new Error(
        `Function argument not provided for authType, got >>>${authType}<<<`
      )
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * @param {string} querySelector
 * @description Returns the DOM element that matches the passed in query
 * selector
 */
const selectDomElement = (querySelector) => {
  try {
    if (querySelector) {
      return document?.querySelector(querySelector)
    } else {
      throw new Error(
        `Function argument not provided for querySelector, got >>>${querySelector}<<<`
      )
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * @param {string} querySelector
 * @description Focuses the DOM element if found by  `selectDomElement`
 */
const focusDomElement = (querySelector) => {
  try {
    if (querySelector) {
      selectDomElement(querySelector)?.focus()
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * @param {string} jsonString JSON string
 *
 * @description Does a check if there is a JSON for parsing if not null is
 * returned, if JSON string is invalid then an error is thrown
 */
const safelyParseJSON = (jsonString = undefined) => {
  if (jsonString) {
    try {
      return JSON.parse(jsonString)
    } catch (error) {
      console.error(error)
    }
  }
  return null
}

/**
 * @param {Array<string>} reasonsArray Rejection reasons array, provided by the
 * `user_details` API
 *
 * @description Separates all possible reasons
 *
 * @returns {Array<string>} Array of rejection reasons
 */
const idRejectionReason = (reasonsArray) => {
  const possibleReasons = {
    possibly_altered: i18Translation('REJECTION_REASON.POSSIBLE_ALTERED_ID'),
    image_not_clear: i18Translation('REJECTION_REASON.IMAGE_NOT_CLEAR'),
    user_confirmed_fields_mismatch: i18Translation(
      'REJECTION_REASON.USER_CONFIRMED_FIELDS_MISMATCH'
    ),
  }

  if (reasonsArray && reasonsArray?.length > 0) {
    return reasonsArray?.map((reason) => possibleReasons[reason])
  }
}

const daysInMonth = (year, month) => new Date(year, month, 0).getDate()

/**
 * @description Returns the number of days in the current month, using local
 * time
 */
const daysCurrentMonth = () =>
  daysInMonth(new Date().getFullYear(), new Date().getMonth() + 1)

/**
 * @description Returns the number of days in the current month, remaining days
 * until the next month and today's date, all this data is in `Number` format
 */
const daysUntilNextPayout = () => {
  const todayDate = new Date().getDate()
  return {
    currentDaysInMonth: daysCurrentMonth(),
    remaining: daysCurrentMonth() - todayDate,
    todayDate,
  }
}

/**
 * @param {object} object1 First object to be checked against the second object
 * @param {object} object2 Second object to be checked against the first object
 *
 * @description Checks if two objects are deeply equal, meaning if their keys
 * values are equal
 */
const deepEqual = (object1, object2) => {
  if (object1 === object2) return true
  if (object1 instanceof Date && object2 instanceof Date)
    return object1.getTime() === object2.getTime()
  if (
    !object1 ||
    !object2 ||
    (typeof object1 !== 'object' && typeof object2 !== 'object')
  )
    return object1 === object2
  if (object1.prototype !== object2.prototype) return false
  const keys = Object.keys(object1)
  if (keys.length !== Object.keys(object2).length) return false
  return keys.every((k) => deepEqual(object1[k], object2[k]))
}

/**
 * @param {object} user_details User's details from the UAS API
 *
 * @description Calculates the number how many KYC alerts need to be rendered
 */
const kycAlertCount = (user_details) => {
  //Initially all flow alerts are set to 0
  let kycAlerts = {
    personalDetails: 0,
    proofOfResidency: 0,
  }

  try {
    if (user_details) {
      //If a user has not completed a KYC for a certain flow, increment the KYC
      //alert count
      if (user_details?.id_review_status === CONSTANTS.NOT_SUBMITTED) {
        kycAlerts = {
          ...kycAlerts,
          personalDetails: kycAlerts.personalDetails + 1,
        }
      }

      return kycAlerts
    } else {
      throw new Error(
        `Value kycAlertCount expected argument got >>>${user_details}<<<`
      )
    }
  } catch (error) {
    console.error(error)
  }
}

/**
 * @param {number} monthNumber Month as a number from 1 to 12
 * @param {string} locale Localizes month, for example if `de-DE` is passed in
 * month string will be in german. Default value us `en-US`
 * @param {string} formatting Formatting of the month, short for `Jan` or long
 * `January`. Default value is `short`
 *
 * @description Converts a a month from number to string with localization
 * option
 */
const monthNumberToString = (
  monthNumber,
  locale = 'en-US',
  formatting = 'short'
) => {
  const dateObject = new Date()
  dateObject.setDate(monthNumber)
  dateObject.setMonth(monthNumber - 1)

  return dateObject.toLocaleString(locale, {
    month: formatting,
  })
}

/**
 * @param {string} locale Locale for the string, if `de-DE` is passed then the
 * weekdays will be in german
 * @param {string} formatting If long or short weekday string should be
 * displayed
 *
 * @description Renders an array of weekday strings starting from Sunday
 */
const dayNumberToString = (locale = 'en-US', formatting = 'short') =>
  Array.from({ length: 7 }).map((_, index) => {
    const dateObject = new Date(1970, 1, 1 + index)
    return dateObject.toLocaleString(locale, {
      weekday: formatting,
    })
  })

/**
 * @param {string} key Key to search by
 * @param {string} value Value to be compared against
 *
 * Queries the `countriesLocale` for passed in search by value, and
 * returns an object containing country information
 */
const getCountryInformation = (key = undefined, value = undefined) => {
  return countriesLocales?.find((country) => country[key] === value)
}

/**
 * Returns nested object property value. Support high level nesting as well as
 * flat objects
 * @param {object} objectToAccess Object that properties will be accessed
 * @param {string} keyPath Key in string format that serves as an object
 * accessor, for example `object.property.property` will return that object's
 * property value
 */
const getObjectPropertyValue = (objectToAccess, keyPath) => {
  const nestedProperties = keyPath?.split('.')
  const propertyValue = nestedProperties?.reduce(
    (currentObject, nestedProperty) => {
      return currentObject ? currentObject[nestedProperty] : undefined
    },
    objectToAccess
  )
  return propertyValue
}

export {
  i18Translation,
  generateRange,
  copyToClipboard,
  browserStorageEnabled,
  millisecondsToSeconds,
  secondsToMinutes,
  getCurrentAge,
  toMinutesAndRemainingSeconds,
  askForBrowserPermission,
  generateApiError,
  sortArray,
  generateUniqueId,
  filterByDateRange,
  idVerificationStatusText,
  hasCompletedL1KYC,
  hasStrongAuth,
  selectDomElement,
  focusDomElement,
  safelyParseJSON,
  idRejectionReason,
  convertDateToClientLocale,
  daysUntilNextPayout,
  deepEqual,
  kycAlertCount,
  daysInMonth,
  monthNumberToString,
  dayNumberToString,
  getCountryInformation,
  getObjectPropertyValue,
}
