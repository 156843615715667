import Divider from '../../data-display/Divider'
import OptionView from './OptionView'
import Option from './Option'
import { deepEqual } from '../../../utils/UtilFunctions'
import { ItemKey, OptionValue } from './Dropdown.types'
import { ASSET } from '../../../constants/Assets'
import style from '../../../scss/components/OptionView.module.scss'

type DropDownMenuItems<T> = {
  options: Array<T>
  itemKey: ItemKey
  value: unknown
  handleOptionClick: (value: T) => void
  noOptionFoundMessage?: string
  block?: string
}

/**
 * Renders dropdown items if there is data otherwise a no data found message is rendered
 */
const DropdownMenuItems = <T,>({
  options,
  handleOptionClick,
  itemKey,
  noOptionFoundMessage,
  block,
  value,
}: DropDownMenuItems<T>) => {
  const optionsToRender = options.map((option, index) => (
    <Option
      value={option}
      key={`option-${(option as OptionValue)?.[itemKey?.displayKey]}${index}`}
      onClick={handleOptionClick}
    >
      <OptionView
        icon={(option as { icon?: string })?.icon}
        text={(option as OptionValue)?.[itemKey?.displayKey]}
        className={
          isSelectedItem(value, option, itemKey?.valueOnChange ?? '')
            ? style['option-view--selected']
            : ''
        }
        secondaryIcon={(option as { secondaryIcon?: string })?.secondaryIcon}
      />

      {index !== options.length - 1 && (
        // Don't render a divider on the last option
        <Divider className={`${block}__divider`} />
      )}
    </Option>
  ))

  return (
    <>
      {optionsToRender?.length === 0 && (
        // Do data found for search query message
        <OptionView
          text={noOptionFoundMessage ?? ''}
          icon={ASSET.iconnotlikelyemojiurey}
          noData
        />
      )}

      {optionsToRender}
    </>
  )
}

/**
 * Checks if a value has been selected from the options list
 */
const isSelectedItem = <T,>(
  selectedValue: T,
  option: T,
  itemKey: string
): boolean => {
  // We are doing an object comparison if not string comparison
  if (selectedValue && typeof selectedValue === 'object') {
    return deepEqual(selectedValue, option as object) as boolean
  }

  if (selectedValue && option) {
    return selectedValue === (option as OptionValue)?.[itemKey]
  }

  return false
}

export default DropdownMenuItems
