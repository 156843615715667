import { useEffect } from 'react'
import { useTranslate } from '../../hooks/useTranslate'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { CONSTANTS } from '../../constants/ConstantValues'
import style from '../../scss/layout/NavBar.module.scss'
import {
  ACCOUNT_MENU,
  DASHBOARD_NAVIGATION,
  PRIVATE,
  PUBLIC,
} from '../../routes/Route'
import AuthButton from '../buttons/AuthButton'
import Button from '../buttons/Button'
import Icon from '../media-and-icons/Icon'
import { hasCompletedL1KYC } from '../../utils/UtilFunctions'
import AccountSummaryBody from '../data-display/AccountSummaryBody'
import MobileBarItems from './MobileBarItems'
import { Location, NavigateFunction, useLocation } from 'react-router-dom'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { ASSET } from '../../constants/Assets'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

/**
 * Top navigation bar displayed only on desktop. It render's user's
 * information, navigation items and a logout button if the user is logged in otherwise
 * a login button
 */
const NavBar = () => {
  const t = useTranslate()
  const navigate = useCustomNavigation()
  const location = useLocation()
  const {
    isAuthenticated,
    context: { user_details },
  } = useAccountService()

  const navigateToSignIn = () => navigate(PUBLIC.SIGN_IN)
  const navigateToSignUp = () => navigate(PUBLIC.SIGN_UP)
  const navigateToHome = () =>
    navigate(
      isAuthenticated ? DASHBOARD_NAVIGATION.FUNDED_PROGRESS : PUBLIC.HOME
    )

  useDesktopRoutes({ navigate, location: location })

  return (
    <nav className={style.navBar}>
      <div className={style.navBar__container}>
        <Icon
          fileName={ASSET.iconmytontineloo}
          className={style.navBar__icon}
          onClick={navigateToHome}
        />

        {isAuthenticated && (
          <MobileBarItems
            isAuthenticated={isAuthenticated}
            className={style['navBar__nav-items']}
            completedKyc={Boolean(hasCompletedL1KYC(user_details))}
          />
        )}

        {isAuthenticated && (
          <article className={style['navBar__acc-container']}>
            <AccountSummaryBody className={style['navBar__acc-sum']} />
            <AuthButton type={CONSTANTS.AUTH_BUTTON_TYPE_LOGOUT_MOBILE} />
          </article>
        )}

        {!isAuthenticated && (
          <article className={style['navBar__auth-section']}>
            <Button
              variant="login"
              onClick={navigateToSignIn}
              dataTestID={UI_TEST_ID.loginBtnDesktop}
              className={
                style[
                  `navBar__login--${
                    location.pathname === PUBLIC.SIGN_IN ? 'hidden' : ''
                  }`
                ]
              }
            >
              {t('BUTTON_LABEL.SIGN_IN')}
            </Button>
            <Button
              variant="signup-desktop"
              onClick={navigateToSignUp}
              dataTestID={UI_TEST_ID.signUpHomeBtn}
              className={
                style[
                  `navBar__signup--${
                    location.pathname === PUBLIC.SIGN_UP ||
                    //Hides sign up button when in referral code redeem mode
                    location?.pathname?.includes(CONSTANTS.REFERRAL_CODE_PREFIX)
                      ? 'hidden'
                      : ''
                  }`
                ]
              }
            >
              {t('BUTTON_LABEL.SIGN_UP')}
            </Button>
          </article>
        )}
      </div>
    </nav>
  )
}

/**
 * Makes sure that on Desktop the root route and the sub route are highlighted
 * at the same time.
 */
const useDesktopRoutes = ({
  navigate,
  location,
}: {
  navigate: NavigateFunction
  location: Location
}) => {
  const { isMobileOrTablet } = useDeviceScreen()

  useEffect(() => {
    if (!isMobileOrTablet) {
      if (location.pathname === PRIVATE.ACCOUNT) {
        navigate(ACCOUNT_MENU.PERSONAL_DETAILS, { replace: true })
      }
      if (location.pathname === PRIVATE.MYTT_DASHBOARD) {
        navigate(DASHBOARD_NAVIGATION.FUNDED_PROGRESS, { replace: true })
      }
    }
  }, [location?.pathname, navigate, isMobileOrTablet])
}

export default NavBar
