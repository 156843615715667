import Card from '../../components/cards/Card'
import { ASSET } from '../../constants/Assets'
import { useLocalization } from '../../hooks/useLocalization'
import { useTranslate } from '../../hooks/useTranslate'
import style from '../../scss/layout/StatCards.module.scss'

const rewardAmount = 250

type StatCardsProps = {
  redeemCount: number | '-'
  countTitle: string
  valueTitle: string
}

/**
 * Renders referral stats with currency values depending where the user is from
 */
const StatCards = ({
  redeemCount = 0,
  countTitle,
  valueTitle,
}: StatCardsProps) => {
  const { formatAmount, detectedCountry } = useLocalization()
  const t = useTranslate()

  return (
    <section className={style['stat-cards']}>
      <article className={style['stat-cards__card-con']}>
        <Card
          headerImage={ASSET.referredWhite}
          headerImageSize="x-large"
          title={countTitle}
          subTitle={redeemCount?.toString()}
          variant="stat"
        />
        <p className={style['stat-cards__card-explainer-text']}>
          {t('TOTAL_REF_EXPLAINER')}
        </p>
      </article>

      <article className={style['stat-cards__card-con']}>
        <Card
          headerImage={ASSET.incentiveWhite}
          headerImageSize="x-large"
          title={valueTitle}
          subTitle={
            typeof redeemCount === 'number'
              ? formatAmount({
                  amount: redeemCount * rewardAmount,
                  currency: detectedCountry?.currency,
                  style: 'currency',
                  digits: { maximumFractionDigits: 0 },
                })?.formattedAmountWithSymbol
              : `-`
          }
          variant="stat"
        />
        <p className={style['stat-cards__card-explainer-text']}>
          {t('PENDING_REWARDS_EXPLAINER')}
        </p>
      </article>
    </section>
  )
}

export default StatCards
