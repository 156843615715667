import { ASSET } from '../../constants/Assets'
import Icon from '../media-and-icons/Icon'
import style from '../../scss/components/PercentTag.module.scss'
import { useLocalization } from '../../hooks/useLocalization'
import Divider from './Divider'
import { useCountUp } from '../../hooks/useCountUpAnimation'
import { ANIMATION } from '../../constants/Animations'
import LottieAnimation from '../feedback/LottieAnimation'

type PercentTagProps = {
  percentage: number
  prefix?: string
  dividerTop?: boolean
  isLoading?: boolean
}

/**
 * Renders a green percentage box with formatted and animated percentage value.
 * The percentage value needs to be a number between 0 and 100
 */
const PercentTag = ({
  percentage,
  prefix,
  dividerTop,
  isLoading,
}: PercentTagProps) => {
  const { formatAmount } = useLocalization()
  const animatedAmount = useCountUp({ targetValue: percentage })

  return (
    <section>
      {dividerTop && <Divider className={style['percent-tag__divider']} />}
      <article className={style['percent-tag__container']}>
        {isLoading ? (
          <LottieAnimation
            autoplay
            loop
            animationName={ANIMATION.whiteTrend}
            style={{
              scale: '0.4',
            }}
          />
        ) : (
          <>
            <Icon
              className={style['percent-tag__icon']}
              fileName={ASSET.whiteTrend}
            />
            <p className={style['percent-tag__amount']}>
              {prefix}
              {
                formatAmount({
                  amount: animatedAmount,
                  style: 'percent',
                  // by default compact because percents should not go
                  // into millions expect here... with our data
                  notation: 'compact',
                })?.formattedAmountWithSymbol
              }
            </p>
          </>
        )}
      </article>
    </section>
  )
}

export default PercentTag
