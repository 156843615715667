/**
 * Provides constant values for the application
 */
const CONSTANTS = Object.freeze({
  //Initial values for input fields
  DATE_PICKER_MODES: {
    DAYS: 'days',
    MONTHS: 'months',
    YEARS: 'years',
    STRICT_MONTHS: 'strict-months',
  },
  JANUARY: 1,
  DECEMBER: 12,
  FEBRUARY: 2,
  LAST_DAY_FEBRUARY: 28,
  LAST_DAY_FEBRUARY_LEAP: 29,
  OUT_OF_RANGE: 0,
  USA_CD_DEPOSIT: 100_000,
  USA_TARGET_MONTHLY: 4_000,

  contribution: 'Contribution',
  payout: 'Payout',

  //Verification status
  NOT_SUBMITTED: null,
  SUBMITTED: 'not_reviewed',
  REJECTED: 'rejected',
  APPROVED: 'approved',

  //Component behavior config like how many fields should a pin input have and
  //input limits max or min
  USA_BANK_ACCOUNT_MAX: 17,
  USA_ACH_ROUTING_NUM_MAX: 9,
  USA_ZIP_CODE_MAX: 10,
  USA_ZIP_DEFAULT_LENGTH: 5,
  MAX_FEEDBACK_LENGTH: 1_200,
  OTP_FIELDS: 6,
  PIN_INPUT_FIELDS: 4,
  SHOW_LESS_PERCENT: 0.28,
  HIDE_PIN: 'password',
  START_OF_CONTENT: 0,
  SLIDER_STYLE_CHROME: '#7ebaf7',
  INFO_MESSAGE_STYLE: '30%',
  DIAL_CODE_MAX_LENGTH: 4,
  STEP_ONE: 1,
  STEP_TWO: 2,
  DISABLED: 'disabled',
  DEFAULT_STYLE: '',
  MONTHS_OLD_MAX: 11,

  //Debounce times
  DEBOUNCE_TIME: 1_000,
  DEBOUNCE_TIME_SWITCHES: 300,
  DEBOUNCE_SLIDERS_TIME: 400,
  API_REQUEST_DEBOUNCE_TIME: 200,
  INCOME_FORECAST_DEBOUNCE_TIME: 2_000,
  SEARCH_DEBOUNCE_MS: 500,

  //Button types
  AUTH_BUTTON_TYPE_LOGIN: 'login',
  AUTH_BUTTON_TYPE_REGISTER: 'register',
  AUTH_BUTTON_TYPE_LOGOUT: 'logout',
  AUTH_BUTTON_TYPE_LOGOUT_MOBILE: 'mobile',

  //Localization or anything that has to do with countries
  FALLBACK_LOCALE: 'en-US',
  FALLBACK_COUNTRY_CODE: 'USA',
  FALLBACK_DIAL_CODE: '+1',
  MAIN_APP_COUNTRY: 'USA',
  INVALID_DATE_ERROR_MSG: 'Invalid date',
  MONTHLY_COMING_SOON: '(coming soon)',
  REQUIRED_FIELD: '*',
  NOT_APPLICABLE: 'N/A',
  READONLY_FIELD: 'read-only',

  //API operations values that control the length of the session or session
  //pooling time.
  CLOSE_MODAL_TIME: 5_000,
  RESEND_EMAIL_TIMER_MILLISECONDS: 30_000,
  SESSION_ABOUT_TO_EXPIRE_SECONDS: 120,
  MAX_FETCH_COUNT: 40,
  SESSION_EXTENSION_LIMIT: 3,

  //Referral program values exclusively for the referral program
  REFERRAL_CODE_EDITING_LIMIT: 2,
  REFERRAL_CODE_PREFIX: 'u/',
  CURRENT_AGE_MIN: 18,

  JAPAN_CURRENCY_SYMBOL: '万円',

  //Formatter values that are used with formatter functions like date and number
  //formatting
  CLOSURE_ACCOUNT_DATE_FORMAT: {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  },
  CONTRIBUTION_HISTORY_MONTH: 'short',
  CONTRIBUTION_HISTORY_YEAR: 'numeric',
  CONTRIBUTION_HISTORY_DAY: 'numeric',
  TRANSACTION_SORT_KEY: 'rawDate',
  TRANSACTIONS_INITIAL_COUNT: 6,
  /**
   * Tests will fail if this is used in a time depending test
   */
  CURRENT_YEAR: new Date().getFullYear(),
  //+1 So months starts from 1, 1 being January
  /**
   * Tests will fail if this is used in a time depending test
   */
  CURRENT_MONTH: new Date().getMonth() + 1,
  /**
   * Tests will fail if this is used in a time depending test
   */
  CURRENT_DAY: new Date().getDate(),
  SSN_FORMAT: 'XXX-XX-XXXX',
  SSN_MAX_DIGIT_LENGTH: 9,
  CURRENCY_DIGITS_FORMATTING: {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  },
  IPHONE5_WIDTH: 320,
  IPHONEX_WIDTH: 375,
  /**
   * Threshold to compact a digit if it passes this number
   */
  LARGE_DIGIT_LIMIT: 8,

  //Browser storage keys
  FACETEC_INIT_KEY: 'faceTecInitializedSuccessfully',
  PHONE_ADDED_KEY: 'phone_added',
  UNIQUE_ID_GENERATOR_RADIX: 36,
  DECIMAL_CUT_START: 2,
  DECIMAL_CUT_END: 15,
  DOB_MODAL_KEY: 'dob_modal_show',
  D3_PERCENT_KEY: '12mo_percent',
  CLOSED_SESSION_EXPIRE_MODAL: 'closed_session_expire_modal',
  AUTH_MACHINE_KEY: 'currentState',
  INVEST_FORM_KEY: 'investmentFormPage',
  FORM_AGREEMENTS_KEY: 'agreementsPage',
  VISITED_EXPLAINER_PAGE: 'visitedLegalPage',
  DEV_AUTH_INFO: 'devAuthInfo',
  LITE_TOKEN: 'verifyToken',
  ANALYTICS_SESSION_ID: 'analyticsId',
  IP_COUNTRY: 'user_country',

  //Keyboard values
  BACKSPACE: 8,
  ENTER_KEY: 13,
})

/**
 * Specific initial values only to the tontinator flow
 */
const CALCULATOR_FORMS = Object.freeze({
  DEFAULT_SHOW_VALUES: {
    infoForm: false,
    sexForm: true,
    ageForm: false,
    contributionForm: false,
    forecast: false,
  },

  GRAPH_TOGGLES_DEFAULT_VALUES: {
    breakeven: false,
    inflation: false,
    percentage: false,
  },
})

const ENVIRONMENTS = Object.freeze({
  development: 'development',
  staging: 'staging',
  production: 'production',
})

const INPUT_TYPE = Object.freeze({
  TEXT: 'text',
  EMAIL: 'email',
  PASSWORD: 'password',
})

const SUPPORTED_BROWSERS_LINKS = Object.freeze({
  GOOGLE_CHROME: 'https://www.google.com/chrome/',
  MOZILLA_FIREFOX: 'https://www.mozilla.org/en-US/firefox/new/',
  OPERA: 'https://www.opera.com/',
  SAFARI: 'https://www.apple.com/safari/',
})

export {
  CONSTANTS,
  CALCULATOR_FORMS,
  ENVIRONMENTS,
  INPUT_TYPE,
  SUPPORTED_BROWSERS_LINKS,
}
