import { useState, useEffect } from 'react'
import { CALCULATOR_FORMS } from '../../constants/ConstantValues'
import Home from '../onboarding/Home'
import AgeForm from './AgeForm'
import ContributionForm from './ContributionForm'
import SexForm from './SexForm'
import Layout from '../../components/layout/Layout'
import { PUBLIC } from '../../routes/Route'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import { useTranslate } from '../../hooks/useTranslate'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { getDetectedIpCountry } from '../../utils/TSUtilFunctions'
import { useSupportedCountries } from '../../hooks/useSupportedCountries'
import { getSupportedTontinatorParams } from '../../utils/TSUtilFunctions'
import BluredForecast from './BluredForecast'
import { isLite } from '../../config/lite'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

/**
 * Forms used for the "HOW IT WORKS" flow, where the user chooses params and
 * sends them for forecasting to the tontinator
 */
const CalculatorForms = () => {
  //Hooks
  const { isMobileOrTablet } = useDeviceScreen()
  const t = useTranslate()
  const navigate = useCustomNavigation()
  const { isAuthenticated } = useAccountService()

  //Controls which form should be rendered
  const [showForms, setShowForms] = useState(
    CALCULATOR_FORMS.DEFAULT_SHOW_VALUES
  )

  const detectedResidencyFromIP = getDetectedIpCountry()

  const {
    supportedCountry: { tontinatorParams: countryBasedDefaultParams },
  } = useSupportedCountries(detectedResidencyFromIP)

  //DEFAULT VALUE FOR ALL THE ONBOARDING FORMS
  const [incomeForecastParams, setIncomeForecastParams] = useState({
    contributionAge: countryBasedDefaultParams?.defaultCurrentAgeSlider,
    retirementAge: countryBasedDefaultParams?.defaultRetirementAgeSlider,
    monthlyContribution: countryBasedDefaultParams?.defaultMonthlySliderValue,
    oneTimeContribution: countryBasedDefaultParams?.defaultOneTimeSliderValue,
    countryOfResidence: detectedResidencyFromIP,
    sex: countryBasedDefaultParams?.defaultSex,
    targetMonthlyIncome: countryBasedDefaultParams.defaultTargetMonthlyIncome,
    target: 'deposit',
  })

  useEffect(() => {
    if (incomeForecastParams?.countryOfResidence) {
      const countryBasedDefaultParams = getSupportedTontinatorParams(
        incomeForecastParams?.countryOfResidence
      )?.tontinatorParams

      setIncomeForecastParams((prevForm) => ({
        ...prevForm,
        contributionAge: countryBasedDefaultParams?.defaultCurrentAgeSlider,
        retirementAge: countryBasedDefaultParams?.defaultRetirementAgeSlider,
        //FIXME: 0 if detected retired
        monthlyContribution:
          countryBasedDefaultParams?.defaultMonthlySliderValue,
        oneTimeContribution:
          countryBasedDefaultParams?.defaultOneTimeSliderValue,
      }))
    }
  }, [incomeForecastParams?.countryOfResidence, detectedResidencyFromIP])

  /**
   * @property {boolean} sexForm Renders sex form
   * @property {boolean} ageForm Renders age form
   * @property {boolean} contributionForm Renders the contribution form
   *
   * @description Controls which tontinator form is rendered on screen by set
   * the `showForms` state
   */
  const renderForm = ({
    sexForm = false,
    ageForm = false,
    contributionForm = false,
    forecastForm = false,
  }) =>
    setShowForms((prevState) => ({
      ...prevState,
      sexForm,
      ageForm,
      contributionForm,
      forecast: forecastForm,
    }))

  /**
   * @description Changes the title depending which form is currently show on
   * screen
   */
  const currentTitle = () => {
    if (showForms.sexForm) {
      return t('ONBOARDING.STEP_1/3_PAGE_TITLE')
    }
    if (showForms.ageForm) {
      return t('ONBOARDING.STEP_2/3_PAGE_TITLE')
    }

    if (showForms.contributionForm || showForms.forecast) {
      return t('ONBOARDING.STEP_3/3_PAGE_TITLE')
    }
  }

  /**
   * @description Used for back button, navigates to the previous screen
   */
  const returnToPreviousForm = () => {
    if (showForms.sexForm) {
      navigate(PUBLIC.HOME)
    }

    if (showForms.ageForm) {
      renderForm({
        sexForm: true,
      })
    }

    if (showForms.contributionForm) {
      renderForm({
        ageForm: true,
      })
    }

    if (showForms.forecast) {
      renderForm({
        contributionForm: true,
      })
    }
  }

  return (
    <>
      {!showForms.forecast && (
        <Layout
          navigateTo={PUBLIC.HOME}
          pageTitle={t('ONBOARDING.SEE_HOW_IT_WORKS_HEADLINE')}
          onClickAction={returnToPreviousForm}
          layoutVariant="sun-bg"
          containerWidth="small"
          containerMt={'nomt'}
          containerHeight={isLite ? 'lite-build' : 'auto'}
          hideDividerHeader
          hideMobileSecondaryIcon
          hideMobileHeader={isLite}
        >
          {showForms.infoForm && <Home setShowForms={setShowForms} />}
          {showForms.sexForm && (
            <SexForm
              formData={incomeForecastParams}
              setFormData={setIncomeForecastParams}
              setShowForms={setShowForms}
              formHeaderText={
                isMobileOrTablet
                  ? currentTitle()
                  : t('ONBOARDING.SEE_HOW_IT_WORKS_HEADLINE')
              }
              previousStep={returnToPreviousForm}
              progress={currentTitle()}
            />
          )}
          {showForms.ageForm && (
            <AgeForm
              formData={incomeForecastParams}
              setFormData={setIncomeForecastParams}
              setShowForms={setShowForms}
              formHeaderText={
                isMobileOrTablet
                  ? currentTitle()
                  : t('ONBOARDING.SEE_HOW_IT_WORKS_HEADLINE')
              }
              previousStep={returnToPreviousForm}
              progress={currentTitle()}
            />
          )}
          {showForms.contributionForm && (
            <ContributionForm
              formData={incomeForecastParams}
              setFormData={setIncomeForecastParams}
              setShowForms={setShowForms}
              formHeaderText={
                isMobileOrTablet
                  ? currentTitle()
                  : t('ONBOARDING.SEE_HOW_IT_WORKS_HEADLINE')
              }
              previousStep={returnToPreviousForm}
              progress={currentTitle()}
            />
          )}
        </Layout>
      )}

      {showForms.forecast && (
        <BluredForecast
          isAuthenticated={isAuthenticated}
          incomeForecastParams={incomeForecastParams}
        />
      )}
    </>
  )
}

export default CalculatorForms
