import { track } from '../../analytics/Analytics'
import { AuthEvent } from '../../analytics/EventData'
import { API_ERROR } from '../../constants/ApiErrors'
import { CONSTANTS } from '../../constants/ConstantValues'
import { TranslatedError } from '../../types/CommonTypes.types'
import { verifiedPersonalDetails } from '../../utils/TSUtilFunctions'
import { generateApiError } from '../../utils/UtilFunctions'
import {
  AuthMachineContext,
  AuthMachineEvent,
  LiteData,
  UserDetails,
} from './AuthMachineTypes.type'

type ContextPropertyParams = {
  event: AuthMachineEvent
  context: AuthMachineContext
}

/**
 * Clears the auth machine's context and wipes stored context in the session
 * storage, only leaving the facetec init flag
 */
const clearAuthContext = {
  authToken: () => {
    const faceTecInitKey = sessionStorage.getItem(CONSTANTS.FACETEC_INIT_KEY)
    // Clears the session storage fully not to leak any auth data
    sessionStorage.clear()
    // Clears the sessions storage, but makes sure facetec stays initialized,
    // in case the user wants to login again
    if (faceTecInitKey) {
      sessionStorage.setItem(CONSTANTS.FACETEC_INIT_KEY, faceTecInitKey)
    }
    // Empty auth token context value
    return ''
  },
  user_details: undefined,
  permissions: undefined,
  refreshToken: undefined,
}
/**
 * Stores returned auth data from an invoked service. Only to be used with
 * events that return `authTokenInfo` and `userAccountInfo`
 */
const storeAuthData = {
  authToken: ({ event }: ContextPropertyParams) => {
    return event?.output?.authTokenInfo.authToken
  },
  permissions: ({ event }: ContextPropertyParams) =>
    event?.output?.authTokenInfo.permissions,
  user_details: ({ context, event }: ContextPropertyParams) => {
    if (event?.output?.userAccountInfo) {
      return verifiedPersonalDetails(event?.output?.userAccountInfo)
    }
    return context.user_details
  },

  refreshToken: ({ event }: ContextPropertyParams) => {
    // Update session storage with refresh token
    sessionStorage.setItem(
      CONSTANTS.AUTH_MACHINE_KEY,
      JSON.stringify(event?.output?.authTokenInfo.refreshToken)
    )

    return event?.output?.authTokenInfo.refreshToken
  },
  remainingTime: ({ event }: ContextPropertyParams) =>
    event?.output?.authTokenInfo.remainingTime,
}

/**
 * Updates the `payout_account` field in the `user_details`
 */
const storePayoutDetails = {
  user_details: ({ context, event }: ContextPropertyParams) => {
    return {
      ...context?.user_details,
      payout_account: {
        ...context?.user_details?.payout_account,
        ...event?.output?.payload?.payoutDetails,
      },
    } as UserDetails
  },
}

/**
 * Wipes the `payout_account` field in the `user_details`
 */
const wipePayoutDetails = {
  // Return type needs to be inffered, because the AuthMachine expects and event
  // always to be used
  user_details: ({ context }: ContextPropertyParams) => {
    return {
      ...context?.user_details,
      payout_account: null,
    } as UserDetails
  },
}

/**
 * Stores auth data excluding the user details, because it only updates session
 * details, like extend session with pin
 */
const storeAuthTokenFromEvent = {
  authToken: ({ event }: ContextPropertyParams) =>
    event?.output?.authTokenInfo.authToken,
  permissions: ({ event }: ContextPropertyParams) =>
    event?.output?.authTokenInfo.permissions,
  refreshToken: ({ event }: ContextPropertyParams) => {
    return event?.output?.authTokenInfo.refreshToken
  },
  remainingTime: ({ event }: ContextPropertyParams) =>
    event?.output?.authTokenInfo.remainingTime,
  extendedSessionTimes: ({ context }: ContextPropertyParams) =>
    context?.extendedSessionTimes + 1,
}

/**
 * Sets the `sessionExpired` data in the `AuthMachineContext`, in order to
 * render a reason a session has expired and to notify the user
 */
const storeExpiredSessionData = {
  sessionExpired: ({
    context,
    event,
  }: {
    context: AuthMachineContext
    event: AuthMachineEvent
  }) => {
    if (event?.payload) {
      return {
        reason: event?.payload?.reason,
        notifyUiWith:
          // If not specified it is an error
          event?.payload?.notifyUiWith,
        renderNotification: event?.payload?.renderNotification,
      }
    }

    // Yeah TS is a bit of skibidi
    const notificationType = 'sessionErrorNotification' as
      | 'normalExpiredNotification'
      | 'sessionErrorNotification'

    if (event?.output) {
      // Error has been thrown and caught by a service so it is added as an
      // event data
      return {
        ...context.sessionExpired,
        notifyUiWith: notificationType,
        renderNotification: true,
      }
    }

    console.warn(
      'No payload or data to update context with, using same values as before'
    )

    return context.sessionExpired
  },
}

/**
 * Sets the context value `isSessionAboutToExpire` to `true` if the session
 * about to expire in order to render a session extension modal
 */
const storeSessionAboutToExpire = {
  sessionAboutToExpire: ({ context, event }: ContextPropertyParams) => {
    if (event?.payload) {
      // Save the change in session storage in order not to spam the user if
      // they refresh
      if (event?.payload?.hasDeclinedSessionExtension) {
        sessionStorage?.setItem(CONSTANTS.CLOSED_SESSION_EXPIRE_MODAL, 'true')
      }

      return {
        ...context?.sessionAboutToExpire,
        secondsRemaining: event?.payload?.secondsRemaining,
        notifyUser: event?.payload?.notifyUser ?? false,
      }
    }

    // Remove the flag, if no payload is passed that means that EXTEND_SESSION
    // has used this action and session has been extended successfully
    sessionStorage?.removeItem(CONSTANTS?.CLOSED_SESSION_EXPIRE_MODAL)

    return undefined
  },
}

/**
 * Updates the `user_details` field in the `AuthMachineContext` from an invoked event
 */
const updateUserDetailsFromEvent = {
  user_details: ({ context, event }: ContextPropertyParams) => {
    if (!event?.output) {
      console.warn(
        'Expected user details data, did not get anything. Context will not be updated'
      )
    }

    // This means an API that should update user account info
    // does not return the user account info, so we use the payload passed in to
    // the API to update the context
    if (event?.output?.payload) {
      return {
        ...context?.user_details,
        ...event?.output?.payload,
      } as UserDetails
    }

    return {
      ...context?.user_details,
      ...event?.output,
    } as UserDetails
  },
}

/**
 * Updates the lite context for MyTontine lite
 */
const updateLiteDetails = {
  liteData: ({ context, event }: ContextPropertyParams) => {
    //TODO: Might be a good idea to reuse this action and update data depending
    //on the event
    if (event?.error) {
      const errorId = event.error?.response?.data?.id
      if (
        errorId === API_ERROR['UAS-AUTH-TOKEN-2'] ||
        errorId === API_ERROR['UAS-AUTH-TOKEN-3']
      ) {
        return context?.liteData
      }
      return {
        ...context?.liteData,
        error: generateApiError(event?.error) as unknown as TranslatedError,
      } as LiteData
    }

    if (!event?.output) {
      console.warn(
        'Expected lite data, did not get anything. Context will not be updated'
      )
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (event.type === 'xstate.done.actor.updateLitePensionPlanID') {
      return {
        ...context?.liteData,
        pensionPlan: { ...event?.output },
      } as LiteData
    }

    return {
      ...context?.liteData,
      ...event?.output,
    } as LiteData
  },
}

/**
 * Clears the context `liteData` and removes the `verifyToken` from storage
 */
const clearLiteData = {
  liteData: () => {
    localStorage?.removeItem(CONSTANTS.LITE_TOKEN)
    //TODO: No idea where to place this, think it is okay here for now
    track({
      event: AuthEvent.logged_out,
      properties: {
        object_id: 'mtl',
      },
    })
    return undefined
  },
}

export {
  clearAuthContext,
  storeAuthData,
  storeAuthTokenFromEvent,
  storeExpiredSessionData,
  storePayoutDetails,
  storeSessionAboutToExpire,
  updateUserDetailsFromEvent,
  wipePayoutDetails,
  updateLiteDetails,
  clearLiteData,
}
