export type TestingIDsType = {
  howItWorksBtn: 'how-it-works-btn'
  signUpHomeBtn: 'sign-up-home-btn'
  loginBtnDesktop: 'login-btn-desktop'
  lastNameInput: 'last-name-input'
  firstNameInput: 'first-name-input'
  emailInput: 'email-input'
  pinInputError: 'pin-input-error-text'
  forgotPinButton: 'forgot-pin-btn'
  backBtnDesktop: 'back-btn-desktop'
  actionButton: 'action-btn'
  editDetailsButton: 'edit-details-btn'
  logoutButtonDesktop: 'logout-btn-desktop'
  faceScanLoginButton: 'face-scan-login-btn'
  faceScanBrowserErrorText: 'facetec-browser-error-text'
  faceScanBrowserErrorLink: 'facetec-browser-error-link'
  closeAccountInitial: 'close-account-initial'
  reactivateAccountButton: 'reactivate-account-btn'
  checkPlansButton: 'check-plans-btn'
  ssnInput: 'ssn-input'
  verifiedFullName: 'verified-full-name'
  usBankAccountNumberInput: 'us-bank-account-number-input'
  usACHRoutingNumberInput: 'us-ach-routing-input'
  streetAddressLine1Input: 'street-address-line1-input'
  streetAddressLine2Input: 'street-address-line2-input'
  cityInput: 'city-input'
  usStateDropdown: 'us-state-dropdown'
  zipCodeInput: 'zip-code-input'
  submitPayoutDetailsButton: 'submit-payout-details-button'
  backPayoutDetailsButton: 'back-payout-details-btn'
  usStateOption: 'us-state-option'
  joinReferralProgramButton: 'join-referral-program-button'
  saveReferralCodeButton: 'save-referral-code-button'
  seeMyContributionHistory: 'see-my-contribution-history'
  inputErrorState: 'userInput__input-element--error'
  clearAllInputFields: 'clear-all-input-fields'
  deleteBankAccountButton: 'delete-bank-account-button'
  confirmDeleteBankAccountButton: 'confirm-delete-bank-account-button'
  termsLabel: 'terms-conditions-clickable-label'
  startFaceScanFromPayout: 'start-face-scan-from-payout'
  openSliderPageButton: 'open-slider-page-button'
  closeSliderPageButton: 'close-slider-page-button'
  breakevenToggle: 'breakeven-toggle'
  showPercentToggle: 'show-percent-toggle'
  showInflationToggle: 'show-inflation-toggle'
  menuItemPinChange: 'menu-item-pin-change'
  menuItemCloseAccount: 'menu-item-close-account'
  editReferralLinkInput: 'edit-referral-link-input'
  editRefLinkButton: 'edit-ref-link-button'
  rootTtDashboard: 'root-tt-dashboard'
  rootAccount: 'root-account'
  incomeSubMenuPayoutDetails: 'income-sub-menu-payout-details'
  menuItemTontinator: 'menu-item-tontinator'
  menuItemDashboardRewards: 'menu-item-dashboard-rewards'
  menuItemNominalBalance: 'menu-item-nominal-balance'
  menuItemFunds: 'menu-item-funds'
  subMenuItemFundsTrans: 'sub-menu-item-funds-trans'
  menuItemIncome: 'menu-item-income'
  subMenuItemIncomeTrans: 'sub-menu-item-income-trans'
  phoneInput: 'phone-input'
  commonCard: 'common-card'
  commonCardExtended: 'common-card-extended'
  commonCardNavigation: 'common-card-navigation'
  cardAlert: 'card-alert'
  cardHeader: 'card-header'
  cardBody: 'card-body'
  cardFooter: 'card-footer'
  cardArrow: 'card-arrow'
  contributionCard: 'contribution-card'
  payoutCard: 'payout-card'
  openComparePlanButton: 'open-compare-plan-button'
  registerButton: 'register-button'
  pickPlan1Button: 'pick-plan-1-button'
  pickPlan2Button: 'pick-plan-2-button'
  currentAgeSliderBox: 'current-age-slider-box'
  currentAgeIncrementButton: 'current-age-increment-button'
  currentAgeDecrementButton: 'current-age-decrement-button'
  retirementAgeSliderBox: 'retirement-age-slider-box'
  retirementAgeIncrementButton: 'retirement-age-increment-button'
  retirementAgeDecrementButton: 'retirement-age-decrement-button'
  oneTimeSliderBox: 'one-time-slider-box'
  oneTimeIncrementButton: 'one-time-increment-button'
  oneTimeDecrementButton: 'one-time-decrement-button'
  monthlySliderBox: 'monthly-slider-box'
  monthlyIncrementButton: 'monthly-increment-button'
  monthlyDecrementButton: 'monthly-decrement-button'
  p2RetirementAgeSliderBox: 'retirement-age-slider-box-p2'
  p2RetirementAgeIncrementButton: 'retirement-age-increment-button-p2'
  p2RetirementAgeDecrementButton: 'retirement-age-decrement-button-p2'
  p2OneTimeRetirementSliderBox: 'one-time-slider-box-p2'
  p2OneTimeIncrementButton: 'one-time-increment-button-p2'
  p2OneTimeDecrementButton: 'one-time-decrement-button-p2'
  retirementSlider: 'retirement-slider'
  currentAgeSlider: 'current-age-slider'
  oneTimeContributionSlider: 'one-time-con-slider'
  monthlyContributionSlider: 'monthly-con-slider'
  selectValueBtn: 'select-value-btn'
  sessionExtensionModal: 'session-extension-modal'
  confirmationModal: 'confirmation-modal'
  modalLogoutButton: 'modal-logout-button'
  updateLitePlanBtn: 'update-lite-plan-btn'
  targetMonthlyIncomeSlider: 'target-monthly-income-slider'
  targetMonthlyIncomeBox: 'target-monthly-income-box'
  targetMonthlyIncomeIncrementButton: 'target-monthly-income-increment-button'
  targetMonthlyIncomeDecrementButton: 'target-monthly-income-decrement-button'
}

export type TestID = TestingIDsType[keyof TestingIDsType]

/**
 * @note Use these *ONLY* in Cypress tests
 * IDs used for testing purposes
 */
const TESTING_IDS: TestingIDsType = {
  howItWorksBtn: 'how-it-works-btn',
  signUpHomeBtn: 'sign-up-home-btn',
  loginBtnDesktop: 'login-btn-desktop',
  lastNameInput: 'last-name-input',
  firstNameInput: 'first-name-input',
  emailInput: 'email-input',
  pinInputError: 'pin-input-error-text',
  forgotPinButton: 'forgot-pin-btn',
  backBtnDesktop: 'back-btn-desktop',
  actionButton: 'action-btn',
  editDetailsButton: 'edit-details-btn',
  logoutButtonDesktop: 'logout-btn-desktop',
  faceScanLoginButton: 'face-scan-login-btn',
  faceScanBrowserErrorText: 'facetec-browser-error-text',
  faceScanBrowserErrorLink: 'facetec-browser-error-link',
  closeAccountInitial: 'close-account-initial',
  reactivateAccountButton: 'reactivate-account-btn',
  checkPlansButton: 'check-plans-btn',
  ssnInput: 'ssn-input',
  verifiedFullName: 'verified-full-name',
  usBankAccountNumberInput: 'us-bank-account-number-input',
  usACHRoutingNumberInput: 'us-ach-routing-input',
  streetAddressLine1Input: 'street-address-line1-input',
  streetAddressLine2Input: 'street-address-line2-input',
  cityInput: 'city-input',
  usStateDropdown: 'us-state-dropdown',
  zipCodeInput: 'zip-code-input',
  submitPayoutDetailsButton: 'submit-payout-details-button',
  backPayoutDetailsButton: 'back-payout-details-btn',
  usStateOption: 'us-state-option',
  joinReferralProgramButton: 'join-referral-program-button',
  saveReferralCodeButton: 'save-referral-code-button',
  seeMyContributionHistory: 'see-my-contribution-history',
  inputErrorState: 'userInput__input-element--error',
  clearAllInputFields: 'clear-all-input-fields',
  deleteBankAccountButton: 'delete-bank-account-button',
  confirmDeleteBankAccountButton: 'confirm-delete-bank-account-button',
  termsLabel: 'terms-conditions-clickable-label',
  startFaceScanFromPayout: 'start-face-scan-from-payout',
  openSliderPageButton: 'open-slider-page-button',
  closeSliderPageButton: 'close-slider-page-button',
  breakevenToggle: 'breakeven-toggle',
  showPercentToggle: 'show-percent-toggle',
  showInflationToggle: 'show-inflation-toggle',
  menuItemPinChange: 'menu-item-pin-change',
  menuItemCloseAccount: 'menu-item-close-account',
  editReferralLinkInput: 'edit-referral-link-input',
  editRefLinkButton: 'edit-ref-link-button',
  rootTtDashboard: 'root-tt-dashboard',
  rootAccount: 'root-account',
  incomeSubMenuPayoutDetails: 'income-sub-menu-payout-details',
  menuItemTontinator: 'menu-item-tontinator',
  menuItemDashboardRewards: 'menu-item-dashboard-rewards',
  menuItemNominalBalance: 'menu-item-nominal-balance',
  menuItemFunds: 'menu-item-funds',
  subMenuItemFundsTrans: 'sub-menu-item-funds-trans',
  menuItemIncome: 'menu-item-income',
  subMenuItemIncomeTrans: 'sub-menu-item-income-trans',
  phoneInput: 'phone-input',
  commonCard: 'common-card',
  commonCardExtended: 'common-card-extended',
  commonCardNavigation: 'common-card-navigation',
  cardAlert: 'card-alert',
  cardHeader: 'card-header',
  cardBody: 'card-body',
  cardFooter: 'card-footer',
  cardArrow: 'card-arrow',
  contributionCard: 'contribution-card',
  payoutCard: 'payout-card',
  openComparePlanButton: 'open-compare-plan-button',
  registerButton: 'register-button',
  pickPlan1Button: 'pick-plan-1-button',
  pickPlan2Button: 'pick-plan-2-button',
  currentAgeSliderBox: 'current-age-slider-box',
  currentAgeIncrementButton: 'current-age-increment-button',
  currentAgeDecrementButton: 'current-age-decrement-button',
  retirementAgeSliderBox: 'retirement-age-slider-box',
  retirementAgeIncrementButton: 'retirement-age-increment-button',
  retirementAgeDecrementButton: 'retirement-age-decrement-button',
  oneTimeSliderBox: 'one-time-slider-box',
  oneTimeIncrementButton: 'one-time-increment-button',
  oneTimeDecrementButton: 'one-time-decrement-button',
  monthlySliderBox: 'monthly-slider-box',
  monthlyIncrementButton: 'monthly-increment-button',
  monthlyDecrementButton: 'monthly-decrement-button',
  p2RetirementAgeSliderBox: 'retirement-age-slider-box-p2',
  p2RetirementAgeIncrementButton: 'retirement-age-increment-button-p2',
  p2RetirementAgeDecrementButton: 'retirement-age-decrement-button-p2',
  p2OneTimeRetirementSliderBox: 'one-time-slider-box-p2',
  p2OneTimeIncrementButton: 'one-time-increment-button-p2',
  p2OneTimeDecrementButton: 'one-time-decrement-button-p2',
  retirementSlider: 'retirement-slider',
  currentAgeSlider: 'current-age-slider',
  oneTimeContributionSlider: 'one-time-con-slider',
  monthlyContributionSlider: 'monthly-con-slider',
  selectValueBtn: 'select-value-btn',
  sessionExtensionModal: 'session-extension-modal',
  confirmationModal: 'confirmation-modal',
  modalLogoutButton: 'modal-logout-button',
  updateLitePlanBtn: 'update-lite-plan-btn',
  targetMonthlyIncomeSlider: 'target-monthly-income-slider',
  targetMonthlyIncomeIncrementButton: 'target-monthly-income-increment-button',
  targetMonthlyIncomeDecrementButton: 'target-monthly-income-decrement-button',
  targetMonthlyIncomeBox: 'target-monthly-income-box',
}

export { TESTING_IDS }
