/**
 * Handles slider state and makes necessary adjustments to the state
 */
export const useSlidersAdjustment = ({
  setFormData,
  formData,
  minRetirementAge,
  maxRetirementAge,
  params,
}) => {
  //This is needed because the slider steps need go from 18 to 85, so that means
  //the slider can go below 50, this makes sure the slider does not go below 50.
  //The reason it is handled like this is to give both the age slider and the
  //retirement slider thumbs equal steps so the thumbs are always alignment...
  const checkRetirementAgeIsBelowMin = (retirementAge, currentAge) => {
    const maxAge = Math.max(minRetirementAge, currentAge)
    return Math.max(retirementAge, maxAge)
  }

  /**
   * Checks if one time and monthly contributions are 0
   */
  const contributionValuesAreZero = (
    oneTimeContribution,
    monthlyContribution
  ) => oneTimeContribution === 0 && monthlyContribution === 0

  /**
   * Adjusts the retirement age and prevents it from going below minimum
   * retirement age
   */
  const adjustRetirementAgeSlider = (currentAge, retirementAge) => {
    if (retirementAge < minRetirementAge) {
      return minRetirementAge
    }

    //Returns the max age, and does not allow the user to move the slider more
    //than the max
    if (retirementAge > maxRetirementAge && currentAge < maxRetirementAge) {
      return maxRetirementAge
    }

    return retirementAge
  }

  /**
   * Adjusts the current age inside the retirement age slider, and allows the
   * retirement slider to modify the current age slider increment or decrement
   */
  const adjustCurrentAgeSliderInsideRetirementAgeSlider = (
    currentAge,
    retirementAge
  ) => {
    //Moving the retirement slider also moves the contribution slider if the USA
    //MIN/MAX conditions are met
    if (currentAge >= maxRetirementAge && retirementAge > maxRetirementAge) {
      return retirementAge
    }

    //Allows the retirement slider to modify the currentAge slider also known as
    //both slider moving at the same time at the same value
    if (currentAge > retirementAge && currentAge > minRetirementAge) {
      return retirementAge
    }

    return currentAge
  }

  /**
   * Makes sure the retirement age is not less than the current age
   */
  const adjustRetirementAgeViaCurrentAgeCall = (retirementAge, currentAge) => {
    if (retirementAge < minRetirementAge) {
      retirementAge = minRetirementAge
    }

    if (currentAge > retirementAge) {
      return currentAge
    }

    //ONLY FOR USA WE NEED TO MODIFY THE BEHAVIOR FOR THE SLIDERS
    if (currentAge < maxRetirementAge && retirementAge > maxRetirementAge) {
      return maxRetirementAge
    }

    if (currentAge >= maxRetirementAge && retirementAge > maxRetirementAge) {
      return currentAge
    }

    return retirementAge
  }

  /**
   * Makes sure that contribution values are no 0 by adjusting the one time
   * contribution
   */
  const adjustOneTimeContribution = (
    monthlyContribution,
    oneTimeContribution
  ) => {
    if (contributionValuesAreZero(oneTimeContribution, monthlyContribution)) {
      return params.oneTimeContributionMinIfOnly
    }
    return oneTimeContribution
  }

  /**
   * Makes sure that contribution values are no 0 by adjusting the monthly
   * contribution
   */
  const adjustMonthlyContribution = (
    monthlyContribution,
    oneTimeContribution
  ) => {
    if (contributionValuesAreZero(monthlyContribution, oneTimeContribution)) {
      return params.monthlyContributionMinIfOnly
    }
    return monthlyContribution
  }

  /**
   * Adjusts contribution values if the user is in a retired state USA or not
   * does not matter
   */
  const adjustContributions = (
    isRetired,
    formData,
    prevFormDataState,
    params
  ) => {
    const { oneTimeContribution } = formData

    //Adjust monthly contribution if user is retired
    const monthlyContributionAdjusted = isRetired
      ? 0
      : prevFormDataState.monthlyContribution

    const oneTimeContributionAdjusted =
      isRetired &&
      //Check adjusted monthly contribution, and adjust the one time
      //contribution to be none zero
      contributionValuesAreZero(
        oneTimeContribution,
        monthlyContributionAdjusted
      )
        ? params.oneTimeContributionMinIfOnly
        : prevFormDataState.oneTimeContribution

    return { oneTimeContributionAdjusted, monthlyContributionAdjusted }
  }

  /**
   * Handles the monthly contribution slider `onChange` event. Before the value
   * is committed to the state it adjustments are made with
   */
  const handleMonthly = (monthlyContribution) => {
    setFormData((prevFormDataState) => {
      const adjustedOneTimeContribution = adjustOneTimeContribution(
        monthlyContribution,
        prevFormDataState.oneTimeContribution
      )

      return {
        ...prevFormDataState,
        monthlyContribution,
        oneTimeContribution: adjustedOneTimeContribution,
      }
    })
  }

  /**
   * Handles the monthly contribution slider `onChange` event. Before the value
   * is committed to the state it adjustments are made with
   */
  const handleOneTime = (oneTimeContribution) => {
    setFormData((prevFormDataState) => {
      const adjustedMonthlyContribution = adjustMonthlyContribution(
        prevFormDataState.monthlyContribution,
        oneTimeContribution
      )
      return {
        ...prevFormDataState,
        oneTimeContribution,
        monthlyContribution: adjustedMonthlyContribution,
      }
    })
  }

  /**
   * Handles current age slider `onChange` event. Before the values are
   * committed to state adjustments are done. The adjustments that this function
   * does are:
   * - Moves the retirement slider if the retirement age is below the current
   *   age `adjustRetirementAgeViaCurrentAgeCall`
   * - Adjusts contribution values if 0 and the user is retired (`currentAge`
   *   === `retirementAge`)
   */
  const handleCurrentAge = (currentAge) => {
    setFormData((prevFormDataState) => {
      const isRetired =
        adjustRetirementAgeSlider(
          prevFormDataState.retirementAge,
          currentAge
        ) === currentAge

      //Adjusts contributions before committing them to the state
      const { oneTimeContributionAdjusted, monthlyContributionAdjusted } =
        adjustContributions(isRetired, formData, prevFormDataState, params)

      //Makes sure the current age slider follow the retirement age slider
      const adjustedRetirementAge = adjustRetirementAgeViaCurrentAgeCall(
        prevFormDataState.retirementAge,
        currentAge
      )

      return {
        ...prevFormDataState,
        contributionAge: currentAge,
        retirementAge: adjustedRetirementAge,
        monthlyContribution: monthlyContributionAdjusted,
        oneTimeContribution: oneTimeContributionAdjusted,
      }
    })
  }

  /**
   * Handles retirement age slider `onChange` event. Before the values are
   * committed to state adjustments are done. The adjustments that this function
   * does are:
   * - Prevents the retirement age going below the current age
   *   `adjustRetirementAgeSlider`
   * - Prevents the retired age going below the the minimum retirement age
   *   `adjustRetirementAgeSlider`
   * - Adjusts contribution values if 0 and the user is retired (`currentAge`
   *   === `retirementAge`)
   */
  const handleRetirementAge = (retirementAge) => {
    setFormData((prevFormDataState) => {
      //Checks if the retirementAge returned from the retirementAge slider is
      //equal to the current age while prevent the retired age from going below
      //the minimum retired age. This is needed to be checked in order to adjust
      //the contribution values
      const isRetired =
        checkRetirementAgeIsBelowMin(
          retirementAge,
          prevFormDataState.contributionAge
        ) === prevFormDataState.contributionAge

      //Adjusts contributions before committing them to the state
      const { oneTimeContributionAdjusted, monthlyContributionAdjusted } =
        adjustContributions(isRetired, formData, prevFormDataState, params)

      //Makes sure retirement age is not below the minimum retired age and
      //current age
      const adjustedRetirementAge = adjustRetirementAgeSlider(
        prevFormDataState.contributionAge,
        retirementAge
      )

      const adjustedContributionAge =
        adjustCurrentAgeSliderInsideRetirementAgeSlider(
          prevFormDataState.contributionAge,
          adjustedRetirementAge
        )

      return {
        ...prevFormDataState,
        retirementAge: adjustedRetirementAge,
        contributionAge: adjustedContributionAge,
        monthlyContribution: monthlyContributionAdjusted,
        oneTimeContribution: oneTimeContributionAdjusted,
      }
    })
  }

  const handleMonthlyTargetIncome = (targetMonthlyIncome) => {
    setFormData((prevFormDataState) => {
      return {
        ...prevFormDataState,
        targetMonthlyIncome,
      }
    })
  }

  return {
    handleMonthly,
    handleOneTime,
    handleRetirementAge,
    handleCurrentAge,
    handleMonthlyTargetIncome,
  }
}
