import { ReactNode, useEffect } from 'react'
import { useBankingService } from '../../state-management/banking/useBankingService'
import SuspenseLoader from '../feedback/SuspenseLoader'
import { useAccountService } from '../../state-management/authentication/useAccountService'
import { useLegalMachine } from '../../state-management/legal/useLegalMachine'
import { Agreement } from '../../state-management/legal/LegalMachineTypes.types'
import { CONSTANTS } from '../../constants/ConstantValues'

/**
 * Can only be fetched if the user is authenticated
 */
const allAgreements: Array<Agreement> = [
  'TontinesAreForLife',
  'YourHealthIsYourWealth',
  'Variability',
  'TermsAndConditions',
]

/**
 * Checks if the UI should wait for the banking data or not
 */
const shouldInitWithBanking = ({
  invAccountStatus,
  bankingData,
}: {
  invAccountStatus: 'opened' | 'none' | 'pending' | 'rejected'
  bankingData: object
}) => {
  if (invAccountStatus === 'opened') {
    return Boolean(bankingData)
  }

  // Skip waiting for banking info, if investment account is not opened
  return true
}

/**
 * Anything wrapped in this component will be rendered only when all data is
 * fetched in authenticated mode and anon mode only for agreements API call
 */
const InitializeUI = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated, context, currentState, states } = useAccountService()
  const { bankContext, sendBankEvent } = useBankingService()
  const { legalContext, sendLegalEvent } = useLegalMachine()
  /**
   * Checks if all necessary agreements are present for auth user
   */
  const isAllAgreementsHere =
    Object.keys(legalContext?.agreement ?? {}).length === allAgreements.length

  useEffect(() => {
    // Do not fetch agreements if there is refresh token indicating that
    // the user is pre-authenticated, otherwise double call will cause issues
    // with double fetch of agreements
    if (!sessionStorage?.getItem(CONSTANTS.AUTH_MACHINE_KEY)) {
      sendLegalEvent({
        type: 'FETCH_AGREEMENT',
        payload: {
          agreementTypes: ['TermsAndConditions'],
        },
      })
    }
  }, [sendLegalEvent])

  useEffect(() => {
    if (isAuthenticated) {
      sendLegalEvent({
        type: 'FETCH_AGREEMENT',
        payload: {
          agreementTypes: allAgreements,
        },
      })
    }
  }, [isAuthenticated, sendLegalEvent])

  useEffect(() => {
    sendBankEvent({
      type: 'GET_RETURNS',
      payload: {
        currencyParamForRate: 'USD',
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (context?.user_details?.investment_account_status === 'opened') {
      sendBankEvent({
        type: 'FETCH_BANKING_INFO',
        payload: {
          authToken: context?.authToken,
        },
      })
    }
  }, [
    context?.user_details?.investment_account_status,
    context?.authToken,
    sendBankEvent,
  ])

  // Only gets called in authenticated state
  if (
    isAuthenticated &&
    context?.user_details &&
    isAllAgreementsHere &&
    currentState !== 'REDEEMING_MAGIC_TOKEN' &&
    shouldInitWithBanking({
      bankingData: bankContext?.bankingInfo,
      invAccountStatus: context?.user_details?.investment_account_status,
    })
  ) {
    return children
  }

  // Necessary data when user is not authenticated
  if (
    legalContext?.agreement &&
    !isAuthenticated &&
    // Needed to show the user the loader instead of the screen flashing
    currentState !== states.REFRESHING_SESSION
  ) {
    return children
  }

  // Checking machine state, if it is fetching or doing something is not a good
  // idea, because the app is in IDLE state which indicates finishing fetching
  // data so it can cause content flashing
  return <SuspenseLoader />
}

export default InitializeUI
