import { assign, fromPromise, setup } from 'xstate'
import { getReturns, readUserBankingInfo } from './BankMachineServices'
import {
  BankMachineContext,
  Events,
  ForecastRules,
} from './BankMachineTypes.type'
import { CONSTANTS } from '../../constants/ConstantValues'
import dayjs from 'dayjs'
import { promiseActorInput } from '../StateUtils'
import {
  AuthMachineEvent,
  Returns,
} from '../authentication/AuthMachineTypes.type'

/**
 * Bank machine configuration
 */
export const bankMachine = setup({
  types: {
    context: {} as BankMachineContext,
    events: {} as Events,
  },
  actors: {
    readUserBankingInfo: fromPromise(
      async ({
        input,
      }: {
        input: { context: BankMachineContext; event: AuthMachineEvent }
      }) => readUserBankingInfo(input?.context, input?.event)
    ),
    getReturnsActor: fromPromise(
      async ({
        input,
      }: {
        input: { context: BankMachineContext; event: AuthMachineEvent }
      }) => getReturns(input?.context, input?.event)
    ),
  },
  actions: {
    updateReturns: assign({
      returns: ({ event }) => {
        return event?.output as {
          returns: Returns
          forecastRules: ForecastRules
        }
      },
    }),

    wipeBankFetchError: assign({
      bankingInfoError: undefined,
    }),

    storeBankingInfo: assign({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      bankingInfo: ({ event }) => {
        const payinHistory = event?.output?.nominalBalance?.filter(
          (balance) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return balance?.transaction?.[`type'`] === CONSTANTS.contribution
          }
        )

        const payoutHistory = event?.output?.nominalBalance?.filter(
          (balance) => {
            // Filters out all payouts that are future, because this is payout history
            const transactionMonth = dayjs(
              (balance as unknown as { transaction: { time: Date } })
                ?.transaction?.time
            ).month()
            const currentMonth = dayjs().month()

            return (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              balance?.transaction?.[`type'`] === CONSTANTS.payout &&
              transactionMonth <= currentMonth
            )
          }
        )

        return {
          ...event?.output,
          payinHistory,
          payoutHistory,
        }
      },
    }),
  },
}).createMachine({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  context: {
    bankingInfo: {
      nominalBalance: [],
    },
    bankingInfoError: undefined,
  },
  id: 'BankMachine',
  initial: 'IDLE',
  states: {
    IDLE: {
      on: {
        FETCH_BANKING_INFO: {
          target: 'FETCHING_BANK_INFO',
        },
        GET_RETURNS: {
          target: 'GETTING_RETURNS',
        },
      },
    },
    GETTING_RETURNS: {
      invoke: {
        src: 'getReturnsActor',
        id: 'getReturnsActorID',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        input: promiseActorInput,
        onDone: {
          target: 'IDLE',
          actions: ['updateReturns'],
        },
        onError: {
          target: 'IDLE',
        },
      },
    },

    FETCHING_BANK_INFO: {
      invoke: {
        src: 'readUserBankingInfo',
        id: 'readUserBankingInfoID',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        input: promiseActorInput,
        onError: {
          target: 'IDLE',
        },
        onDone: {
          target: 'IDLE',
          actions: ['storeBankingInfo', 'wipeBankFetchError'],
        },
      },
      description:
        'Makes a parallel request to multiple BS APIs to fetch all banking information',
    },
  },
})
