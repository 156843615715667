import { useEffect } from 'react'
import { allowedOrigins } from '../constants/TontineOrigins'
import { tontineWebsiteOrigin } from 'MyTontineConfig'

type EventData = {
  track: boolean
  consentCookie?: string
  // TODO: Type this
  changeConsent?: { marketing: boolean }
  cookieBot: object
}

type Event = MessageEvent<{
  source: 'MTL'
  // Only event Id issues from the webapp
  payload: {
    eventId: 'TERMINATE_REF_SESSION'
    eventData: EventData
  }
}>

const allowedEventsToListen = [
  'TERMINATE_REF_SESSION',
  'CONSENT_COOKIE',
  'CONSENT_CHANGE',
] as const
const allowedEventSources = ['MTL'] as const

/**
 * Listens for messages from allowed origins and allowed event ids
 */
const useMessageListener = (
  onMessageReceived: ({
    eventId,
    source,
    origin,
    eventData,
  }: {
    eventId: 'TERMINATE_REF_SESSION' | 'CONSENT_CHANGE' | 'CONSENT_COOKIE'
    source: Event['source']
    origin: string
    eventData: EventData
  }) => void
) => {
  useEffect(() => {
    window.onmessage = (event: Event) => {
      if (allowedOrigins.includes(event?.origin)) {
        if (allowedEventsToListen.includes(event?.data?.payload?.eventId)) {
          onMessageReceived({
            eventId: event?.data?.payload?.eventId,
            source: event?.source,
            origin: event?.origin,
            eventData: event?.data?.payload?.eventData,
          })
        }
      }
    }
  }, [onMessageReceived])
}

/**
 * Sends the host parent a message. The origin is already included, no need to
 * specify
 */
const postMessageToParent = ({
  eventId,
  eventData,
}: {
  eventId:
    | 'SUCCESS_VERIFY'
    | 'REDIRECT'
    | 'TERMINATE_WEB_SESSION'
    | 'ASK_FOR_CONSENT_COOKIE'
  eventData?: object | string
}) => {
  window?.parent?.postMessage(
    {
      source: allowedEventSources[0],
      payload: { eventId, eventData },
    },
    tontineWebsiteOrigin
  )
}

export { useMessageListener, postMessageToParent }
