import style from '../../scss/layout/InputGroup.module.scss'

/**
 * Groups input fields. Passing in `noStyle` ignores the
 * main container styling and just uses this component as a wrapper
 */
const InputGroup = ({
  children,
  dataTestID,
  noStyle,
  groupLabel,
  className,
  variant,
  borderColor,
}: {
  children?: React.JSX.Element | Array<React.JSX.Element>
  dataTestID?: string
  noStyle?: boolean
  groupLabel?: string
  className?: string
  variant?: 'bordered'
  borderColor?: 'yellow' | 'light-blue'
}) => {
  return (
    <main
      className={`${style[`input-group${variant ? `--${variant}-${borderColor}` : ''}`]} ${className ?? ''}`}
    >
      <h3
        className={`${style[`input-group__label${variant ? `--${variant}` : ''}`]}`}
      >
        {groupLabel}
      </h3>

      <section
        className={
          noStyle ? '' : `${style[`input-group__container`]} ${className ?? ''}`
        }
        data-testid={dataTestID}
      >
        {children}
      </section>
    </main>
  )
}

export default InputGroup
