import Icon from '../../media-and-icons/Icon'
import style from '../../../scss/components/OptionView.module.scss'

type OptionViewProps = {
  icon?: string
  text: string
  className?: string
  noData?: boolean
  isBackgroundOption?: boolean
  secondaryIcon?: string
}

/**
 * Renders a dropdown view with an icon and text, if `icon` is passed in renders
 * an icon. Passing in a country code alpha2 will render a flag
 */
const OptionView = ({
  icon,
  text,
  className,
  noData,
  isBackgroundOption,
  secondaryIcon,
}: OptionViewProps) => {
  return (
    <div
      className={`${style[`option-view`]} ${className ?? ''} ${noData ? style[`option-view--no-data`] : ''} ${
        isBackgroundOption ? style[`option-view--back-option`] : ''
      }`}
    >
      <div className={style[`option-view__container`]}>
        {icon && (
          <Icon className={style[`option-view__icon`]} fileName={icon} />
        )}
        <p className={style[`option-view__text`]}>{text}</p>
      </div>

      {secondaryIcon && (
        <Icon className={style[`option-view__icon`]} fileName={secondaryIcon} />
      )}
    </div>
  )
}

export default OptionView
