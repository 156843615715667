import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { useMessageListener, postMessageToParent } from './useWindowPostMessage'
import { mixpanelProxy } from 'MyTontineConfig'

/**
 * Handles mixpanel tracking initialization and handles tracking consent
 */
export const useTracking = () => {
  const optInOrOutOfTracking = (marketing: boolean) => {
    if (marketing) {
      mixpanel.set_config({ ignore_dnt: true })
      mixpanel.opt_in_tracking()
    } else {
      mixpanel.opt_out_tracking()
    }
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    mixpanel.init(import.meta.env.VITE_MIX_PANEL_API_KEY ?? '', {
      persistence: 'cookie',
      // GDPR Needs to be opt out by default, DO NOT MODIFY!
      opt_out_tracking_by_default: true,
      api_host: mixpanelProxy,
    })
  }, [])

  useEffect(() => {
    postMessageToParent({
      eventId: 'ASK_FOR_CONSENT_COOKIE',
    })
  }, [])

  useMessageListener(({ eventId, eventData }) => {
    if (eventId === 'CONSENT_CHANGE') {
      optInOrOutOfTracking(eventData?.changeConsent?.marketing ?? false)
    }

    if (eventId === 'CONSENT_COOKIE') {
      optInOrOutOfTracking(eventData?.track ?? false)
    }
  })
}
