import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useCustomNavigation } from '../../hooks/useCustomNavigation'
import {
  postMessageToParent,
  useMessageListener,
} from '../../hooks/useWindowPostMessage'
import { useAccountService } from '../authentication/useAccountService'
import { LiteContext } from './LiteAuthContext'
import { States } from '../authentication/AuthMachineTypes.type'

/**
 * Auth provider for Lite version of MyTontine.
 * - Verifies MTL email and gets user data
 * - Iframe listeners listen for events from the tontine.com website
 */
const LiteAuthProvider = ({ children }: { children: ReactNode }) => {
  const { send, currentState, context } = useAccountService()
  const navigate = useCustomNavigation()
  const location = useLocation()
  const verify_token = (() => {
    if (location?.pathname) {
      // Get path and verify token, ignoring the empty string from split
      const [verifyPath, verifyToken] = location.pathname.split('/').slice(1)
      if (verifyPath === 'verify') {
        return verifyToken
      }
    }
    return undefined
  })()

  // alternative solution would be
  // if there is no URL param to wait for website confirmation
  // to start verifying email

  useEffect(() => {
    send({
      type: 'VERIFY_EMAIL_MTL',
      payload: {
        verifyToken: verify_token,
        successCallback: (data) => {
          // Sends a parent (page that embeds this via iframe) an event
          postMessageToParent({
            eventId: 'SUCCESS_VERIFY',
            eventData: data as object,
          })
        },
        failureCallback: () =>
          postMessageToParent({
            eventId: 'TERMINATE_WEB_SESSION',
          }),
        finallyCallback: () => {
          if (verify_token) {
            navigate('/', { replace: true })
          }
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Message to terminate session is issued, propagate the whole isAuth state
  useMessageListener(({ eventId, source, origin }) => {
    if (eventId === 'TERMINATE_REF_SESSION') {
      send({
        type: 'TERMINATE_MTL_SESSION',
      })
      // Reply to the website that session has been terminated
      source?.postMessage(
        {
          payload: { eventId: 'TERMINATE_SUCCESS' },
        },
        {
          targetOrigin: origin,
        }
      )
    }
  })

  return (
    <LiteContext.Provider
      value={{
        isAuth: Boolean(context?.liteData?.liteAuthToken),
        referralDetails: context?.liteData?.referralDetails,
        litePensionPlan: context?.liteData?.pensionPlan,
        error: context?.liteData?.error,
        isLoading: currentState === 'VERIFYING_EMAIL_MTL',
        verifyToken: verify_token,
        currentState: currentState as unknown as States,
      }}
    >
      {children}
    </LiteContext.Provider>
  )
}

export default LiteAuthProvider
