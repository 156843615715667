import IncomeStats from './IncomeStats'
import StaticToolTip from '../feedback/StaticToolTip'
import style from '../../scss/components/IncomeStats.module.scss'
import parse from 'html-react-parser'
import { IncomeStatsExtendedProps } from './DataDisplay.types'
import { ANIMATION } from '../../constants/Animations'
import LottieAnimation from '../feedback/LottieAnimation'

const variantToColor: {
  [key in 'blue-faint' | 'yellow-faint' | 'grey']: 'blue' | 'gold' | 'grey'
} = {
  'blue-faint': 'blue',
  'yellow-faint': 'gold',
  grey: 'grey',
}

/**
 * Applies style to the i18n variables by replacing the values with
 * JSX styled elements
 */
const styleVariables = (
  bottomText: string,
  incomeStartAge: number,
  startingIncomeAtAge: string
) => {
  if (bottomText) {
    const modifiedText = bottomText
      .replace(
        incomeStartAge?.toString(),
        `<p className=${style['income-stats-extended__years-old']}>
          ${incomeStartAge}
        </p>`
      )
      .replace(
        startingIncomeAtAge,
        `<p className=${style['income-stats-extended__years-old']}>
        ${startingIncomeAtAge}
      </p>`
      )

    return (
      <div className={style['income-stats-extended__text-msg']}>
        {parse(modifiedText)}
      </div>
    )
  }

  return ''
}

/**
 * Adds a bottom text to the income stats component and a static tooltip
 */
const IncomeStatsExtended = ({
  bottomText,
  incomeStartAge,
  startingIncomeAtAge,
  staticToolTipText,
  contributionAmount,
  contributionLabel,
  currency,
  incomeAmount,
  incomeLabel,
  incomePercentage,
  isLoading,
  variant = 'blue-faint',
  hideTooltip,
}: IncomeStatsExtendedProps) => {
  return (
    <div className={style['income-stats-extended']}>
      {!hideTooltip && (
        <StaticToolTip
          color={variantToColor[variant]}
          text={staticToolTipText}
          rightTip
        />
      )}

      <article
        className={
          style[
            `income-stats-extended__container${variant ? `--${variant}` : ''}`
          ]
        }
      >
        <IncomeStats
          contributionAmount={contributionAmount}
          currency={currency}
          contributionLabel={contributionLabel}
          incomeAmount={incomeAmount}
          incomeLabel={incomeLabel}
          incomePercentage={incomePercentage}
          isLoading={isLoading}
          variant={variant}
        />
        {!isLoading && contributionAmount > 0 ? (
          <div className={style['income-stats-extended__bottom-container']}>
            {bottomText &&
              styleVariables(bottomText, incomeStartAge, startingIncomeAtAge)}
          </div>
        ) : (
          <LottieAnimation
            loop
            autoplay
            animationName={ANIMATION.loadingLightBlueDots}
            style={{
              // DO not change
              height: '34px',
              scale: '5',
            }}
          />
        )}
      </article>
    </div>
  )
}

export default IncomeStatsExtended
