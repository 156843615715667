import { useMemo } from 'react'
import { useBankingService } from '../state-management/banking/useBankingService'
import { getCountryInformation } from '../utils/UtilFunctions'
import { ForecastRules } from '../state-management/banking/BankMachineTypes.type'
import { getSupportedTontinatorParams } from '../utils/TSUtilFunctions'

/**
 * Returns appropriate rules for making forecasts within the max and min limits
 */
const findForecastRulesForCountry = ({
  alpha3CountryCode,
  forecastRules,
}: {
  alpha3CountryCode: string
  forecastRules: ForecastRules
}) => {
  // Some regions are countries itself, so we can get a quick win to return the
  // necessary country easily
  let foundCountry = forecastRules?.[alpha3CountryCode]

  if (!foundCountry) {
    // No country found look for regions
    Object.keys(forecastRules).forEach((region) => {
      // Look up the countries for the region
      forecastRules?.[region]?.countries.find((country) => {
        if (country === alpha3CountryCode) {
          // if country matches he country in the region
          // init the rules for that country
          foundCountry = forecastRules?.[region]
        }
        // .find to stop needs this
        return country === alpha3CountryCode
      })
    })
  }

  if (foundCountry) {
    return {
      currency: foundCountry?.currency,
      rules: {
        minRetirementAge: foundCountry?.thresholds?.payout_start_min,
        maxRetirementAge: foundCountry?.thresholds?.payout_start_max,
        minCurrentAge: foundCountry?.thresholds?.contribution_start_min,
        maxCurrentAge: foundCountry?.thresholds?.payout_start_max,
      },
      supportedInvestments: foundCountry?.supportedInvestments,
    }
  }

  return {}
}

/**
 * Returns memorized supported country information with default tontinator
 * params and limits from the backend
 */
export const useSupportedCountries = (
  alpha3CountryCode?: string,
  onCountryChanged?: (supportedCountry?: object) => void
) => {
  const { bankContext } = useBankingService()

  const supportedCountry = useMemo(() => {
    let country = getCountryInformation('alpha3', alpha3CountryCode)

    if (!country) {
      // If country that is not in the json list
      // default to Ireland
      country = getCountryInformation('alpha3', 'IRL')
    }

    const limitsFromBackend = findForecastRulesForCountry({
      alpha3CountryCode: country?.alpha3 ?? 'IRL',
      forecastRules: bankContext?.returns?.forecastRules ?? {},
    })
    const uiDefaultValues = getSupportedTontinatorParams(
      country?.alpha3 ?? 'IRL'
    )

    const countryInfo = {
      ...country,
      currency: limitsFromBackend?.currency,
      supportedInvestments: limitsFromBackend?.supportedInvestments,
      tontinatorParams: {
        ...limitsFromBackend.rules,
        ...uiDefaultValues,
      },
    }

    onCountryChanged?.(countryInfo)

    return countryInfo
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alpha3CountryCode, onCountryChanged])

  return {
    supportedCountry,
  }
}
