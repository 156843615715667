import { useSearchParams } from 'react-router-dom'
import { CONSTANTS } from '../constants/ConstantValues'
import { getDetectedIpCountry, numberFormatter } from '../utils/TSUtilFunctions'
import { useEffect } from 'react'
import { useSupportedCountries } from './useSupportedCountries'

const PERCENT_DIGITS_FORMATTING = {
  maximumFractionDigits: 1,
  minimumFractionDigits: 1,
}

const CURRENCY_DIGITS_FORMATTING = {
  maximumSignificantDigits: 4,
  minimumSignificantDigits: 1,
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
}

/**
 * Provides functions for formatting number values and other localization
 * options based on user residency for now. This is a soft "settings" feature
 * which will scale into a settings page where the user would be able to save
 * settings on how they want values in the app to be formatted.
 */
export const useLocalization = () => {
  const [urlSearchParams] = useSearchParams()
  const res = urlSearchParams.get('country')?.toUpperCase()
  const country = res ?? getDetectedIpCountry()
  const { supportedCountry } = useSupportedCountries(country)

  const isVerySmallScreen =
    window?.innerWidth >= CONSTANTS.IPHONE5_WIDTH &&
    window?.innerWidth < CONSTANTS.IPHONEX_WIDTH

  useEffect(() => {
    if (supportedCountry.alpha3) {
      localStorage.setItem(
        CONSTANTS.IP_COUNTRY,
        supportedCountry.alpha3 === 'USA' ? 'USA' : supportedCountry.alpha3
      )
    }
  }, [supportedCountry?.alpha3])

  /**
   * Formats a number based on residency from `user_details` and passed in `style`
   * and returns a formatted string with a currency symbol. Passed in params have
   * priority over `user_details`.
   */
  const formatAmount = ({
    amount = 0,
    currency,
    style,
    notation,
    digits,
  }: {
    amount: number | bigint
    currency?: string
    style?: 'percent' | 'currency'
    notation?: 'standard' | 'engineering' | 'compact' | 'scientific'
    digits?: {
      minimumFractionDigits?: number
      maximumFractionDigits?: number
      maximumSignificantDigits?: number
      minimumSignificantDigits?: number
    }
  }):
    | {
        formattedAmountWithSymbol: string
        symbol: string
        formattedAmount: string
      }
    | undefined => {
    try {
      const isLargeDigit =
        amount?.toString()?.length > CONSTANTS.LARGE_DIGIT_LIMIT ||
        isVerySmallScreen

      const userCountryInformation = supportedCountry

      let amountToFormat = amount
      const modifiedNotation = isLargeDigit ? 'compact' : notation

      if (typeof amount === 'bigint') {
        //FIXME: Converting bigint to Number can lose precision
        //But we do not have a use case where we use big int
        //This will be fine for a while
        amountToFormat = Number(amount)
        if (style === 'percent') {
          amountToFormat /= 100
        }
      } else if (typeof amount === 'number') {
        amountToFormat = style === 'percent' ? amount / 100 : amount
      } else {
        throw new TypeError(
          `Amount is not a number or bigint got >>${amount as string}<<`
        )
      }

      const formattedAmountWithSymbol = numberFormatter(
        amountToFormat,
        userCountryInformation?.default_locale,
        {
          currency: currency ?? userCountryInformation?.currency,
          style,
          notation: modifiedNotation,
          ...digits,
        }
      )
      //USA want to see the decimals
      const symbol = formattedAmountWithSymbol.replace(/[\d.,]/g, '').trim()

      const formattedAmount = numberFormatter(
        amountToFormat,
        userCountryInformation?.default_locale,
        {
          style: 'decimal',
          notation: modifiedNotation,
        }
      )

      return {
        formattedAmountWithSymbol,
        symbol,
        formattedAmount,
      }
    } catch (error) {
      console.error(error)
    }

    return undefined
  }

  /**
   * Formats tontinator returns with abstracted settings
   */
  const formatTontinatorAmount = ({
    amount,
    style,
    currency,
  }: {
    amount: number
    style: 'percent' | 'currency'
    currency: string
  }) => {
    if (amount) {
      const isPercentage = style === 'percent'
      return (
        formatAmount({
          amount: isPercentage ? amount : Math.trunc(amount),
          style,
          currency,
          digits: isPercentage
            ? PERCENT_DIGITS_FORMATTING
            : CURRENCY_DIGITS_FORMATTING,
        })?.formattedAmountWithSymbol ?? amount
      )
    }

    return ''
  }

  return {
    formatAmount,
    formatTontinatorAmount,
    isUSA: country === 'USA',
    detectedCountry: supportedCountry,
  }
}
