import PropTypes from 'prop-types'
import RangeSlider from './RangeSlider'
import { isLite } from '../../../config/lite'
import { UI_TEST_ID } from '../../../constants/DataTestIDs'
import SelectTarget from '../../buttons/SelectTarget'

/**
 * Renders an slider input for monthly and one time contribution. The one
 * time contribution slider can be changed with select value component to
 * monthly target income
 */
const ContributionSliders = ({
  monthlyContribution,
  setMonthlyContribution,
  monthlySteps,
  oneTimeSteps,
  oneTimeStepsIfRetired,
  oneTimeContribution,
  setOneTimeContribution,
  formatter,
  prefix,
  labelMonthly,
  labelOneTime,
  className,
  disabledSliderTooltipText,
  isRetired,
  variant,
  monthlyBoxDataTestID,
  oneTimeBoxDataTestID,
  incOneTimeDataTestID,
  incMonthlyDataTestID,
  decOneTimeDataTestID,
  decMonthlyDataTestID,
  trackActivityOneTime,
  trackRangeOneTime,
  enabledStepsMonthly,
  target,
  setTarget,
  hideTargetInput,
  targetMonthlyIncomeSteps,
  targetMonthlyIncome,
  targetMonthlyLabel,
  targetMonthlyBoxDataTestID,
  targetMonthlyIncrementButtonDataTestID,
  targetMonthlyDecrementButtonDataTestID,
  setMonthlyTargetIncome,
  trackActivityMonthlyTarget,
}) => {
  const commonSliderProps = {
    prefix,
    formatter,
    variant,
  }

  return (
    <main className={className}>
      {!hideTargetInput ? (
        <SelectTarget
          target={target}
          setTarget={setTarget}
          trackActivities={[
            {
              trackId: 'tontinator_deposit_mode',
            },
            {
              trackId: 'tontinator_monthly_target',
            },
          ]}
        />
      ) : (
        // FIXME: Quick fix to hide the target monthly input for BOLD
        // for now until it is fixed
        <div style={{ width: '355px' }} />
      )}

      {target === 'deposit' ? (
        <RangeSlider
          {...commonSliderProps}
          onChange={setOneTimeContribution}
          steps={isRetired ? oneTimeStepsIfRetired : oneTimeSteps}
          label={labelOneTime}
          value={oneTimeContribution}
          boxValueDataTestID={oneTimeBoxDataTestID}
          incrementButtonDataTestID={incOneTimeDataTestID}
          decrementButtonDataTestID={decOneTimeDataTestID}
          sliderTestID={UI_TEST_ID.oneTimeContributionSlider}
          trackActivity={trackActivityOneTime}
          trackRangeActivity={trackRangeOneTime}
        />
      ) : (
        <RangeSlider
          {...commonSliderProps}
          onChange={setMonthlyTargetIncome}
          steps={targetMonthlyIncomeSteps}
          label={targetMonthlyLabel}
          value={targetMonthlyIncome}
          boxValueDataTestID={targetMonthlyBoxDataTestID}
          incrementButtonDataTestID={targetMonthlyIncrementButtonDataTestID}
          decrementButtonDataTestID={targetMonthlyDecrementButtonDataTestID}
          sliderTestID={UI_TEST_ID.targetMonthlyIncomeSlider}
          trackActivity={trackActivityMonthlyTarget}
          trackRangeActivity={trackRangeOneTime}
        />
      )}

      {!isLite && (
        <RangeSlider
          {...commonSliderProps}
          disabled={isRetired}
          onChange={setMonthlyContribution}
          steps={monthlySteps}
          label={labelMonthly}
          value={monthlyContribution}
          disabledSliderTooltipText={disabledSliderTooltipText}
          boxValueDataTestID={monthlyBoxDataTestID}
          incrementButtonDataTestID={incMonthlyDataTestID}
          decrementButtonDataTestID={decMonthlyDataTestID}
          sliderTestID={UI_TEST_ID.monthlyContributionSlider}
          enabledSteps={enabledStepsMonthly}
        />
      )}
    </main>
  )
}

ContributionSliders.propTypes = {
  retirementAge: PropTypes.number,
  currentAge: PropTypes.number,
  monthlyContribution: PropTypes.number,
  setMonthlyContribution: PropTypes.func,
  monthlySteps: PropTypes.arrayOf(PropTypes.number),
  monthlyMinIfOnly: PropTypes.number,
  oneTimeContribution: PropTypes.number,
  setOneTimeContribution: PropTypes.func,
  oneTimeSteps: PropTypes.arrayOf(PropTypes.number),
  oneTimeStepsIfRetired: PropTypes.arrayOf(PropTypes.number),
  oneTimeMinIfOnly: PropTypes.number,
  formatter: PropTypes.func,
  prefix: PropTypes.string,
  labelMonthly: PropTypes.string,
  labelOneTime: PropTypes.string,
  className: PropTypes.string,
  disabledSliderTooltipText: PropTypes.string,
  isRetired: PropTypes.bool,
  variant: PropTypes.string,
  monthlyBoxDataTestID: PropTypes.string,
  oneTimeBoxDataTestID: PropTypes.string,
  incOneTimeDataTestID: PropTypes.string,
  incMonthlyDataTestID: PropTypes.string,
  decOneTimeDataTestID: PropTypes.string,
  decMonthlyDataTestID: PropTypes.string,
  trackActivityOneTime: PropTypes.object,
  trackRangeOneTime: PropTypes.object,
  enabledStepsMonthly: PropTypes.array,
  target: PropTypes.string,
  setTarget: PropTypes.func,
  hideTargetInput: PropTypes.bool,
  targetMonthlyIncomeSteps: PropTypes.array,
  targetMonthlyIncome: PropTypes.number,
  targetMonthlyLabel: PropTypes.string,
  targetMonthlyBoxDataTestID: PropTypes.string,
  targetMonthlyIncrementButtonDataTestID: PropTypes.string,
  targetMonthlyDecrementButtonDataTestID: PropTypes.string,
  setMonthlyTargetIncome: PropTypes.func,
  trackActivityMonthlyTarget: PropTypes.object,
}

export { ContributionSliders }
