import { uas, facetec } from 'MyTontineConfig'
import axios, { AxiosResponse } from 'axios'
import { API_STATUS } from '../../constants/ApiErrors'
import { CONSTANTS } from '../../constants/ConstantValues'
import { axiosConfig } from '../../api/RequestConfig'
import { API } from '../../api/API'
import { tontineAuthTS } from '../../api/legacy/AuthenticationTS'
import {
  adjustAndConvertToAgeMonthString,
  parseLiteParams,
  parseLiteReferralData,
  verifiedPersonalDetails,
} from '../../utils/TSUtilFunctions'
import { generateUniqueId, safelyParseJSON } from '../../utils/UtilFunctions'
import { mockFaceScan, writeToConsoleAndIssueAlert } from '../StateUtils'
import {
  AuthMachineContext,
  AuthMachineEvent,
  AuthMachineSelf,
  AuthTokenInfo,
  LiteData,
  LitePensionPlan,
  MagicLoginResponse,
  PayoutAccountDetails,
  UserDetails,
} from './AuthMachineTypes.type'
import { _initSDK, _startFaceScan } from './Biometrics'
import {
  ReferralStat,
  UserReferralStats,
} from '../../pages/referral/ReferralTypes.type'
import { isLite } from '../../config/lite'
import { track } from '../../analytics/Analytics'
import { AuthEvent } from '../../analytics/EventData'
import { IncomeForecastRequestBody } from '../../types/CommonTypes.types'
import { BackendErrorId } from '../../constants/ApiErrors.types.js'
import { EVENT_DESC } from '../../analytics/EventDescription'

let webSocket: WebSocket | null = null
let currentWebSocketID: string | null = null

/**
 * @hidden
 * Opens a websocket connection with the UAS in order to get realtime updates on
 * the server session
 */
const _openWebSocket = (
  authToken: string,
  context: AuthMachineContext,
  self: AuthMachineSelf
) => {
  const webSocketID = generateUniqueId()
  currentWebSocketID = webSocketID

  let hasIssuedEvent =
    // Has issues event is set to true because the user decided not to extend
    // their session, so we do not spam the user if they reload with F5
    sessionStorage?.getItem(CONSTANTS?.CLOSED_SESSION_EXPIRE_MODAL) ?? false

  // Session extension is limited to 3 times per login session
  const hasExtendedSessionExtensionLimit =
    context?.extendedSessionTimes === CONSTANTS.SESSION_EXTENSION_LIMIT

  const handleServerResponse = (response: AxiosResponse): void => {
    const sessionSecondsRemaining = safelyParseJSON(
      response?.data as string
    ) as number

    //Server has responded with 0 seconds, meaning there is no active session
    //for the sent auth token or the auth token was invalid
    if (sessionSecondsRemaining <= 0) {
      //Make sure to only issue EXPIRE_SESSION to only the CURRENT web socket
      //connection
      if (currentWebSocketID === webSocketID) {
        // This event will be fired on a closing handshake. But the APP has
        // already exited AUTH_TOKEN state, so this event will be ignored
        // anyway.
        self.send({
          type: 'EXPIRED_SESSION',
          payload: {
            reason:
              'Session expired normally server responded with no session for given auth token',
            notifyUiWith: 'normalExpiredNotification',
            renderNotification: true,
          },
        })
      }
    }

    //Makes sure the session expire event is not sent when the user has an
    //invalid auth token
    if (
      sessionSecondsRemaining <= CONSTANTS.SESSION_ABOUT_TO_EXPIRE_SECONDS &&
      sessionSecondsRemaining > 0 &&
      !hasIssuedEvent &&
      !hasExtendedSessionExtensionLimit
    ) {
      self.send({
        type: 'SESSION_ABOUT_TO_EXPIRE',
        payload: {
          secondsRemaining: sessionSecondsRemaining,
          notifyUser: true,
        },
      })
      hasIssuedEvent = true
    }
  }

  webSocket = tontineAuthTS.webSocketConnection({
    url: `${API.checkSessionWSS}${authToken}`,
    //Only for this function ts is disabled, because looks like the ts type is
    //bugged or something not sure
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    onMessageReceivedFromServer: handleServerResponse,
    //Leave these like this for now, I think this will change very soon
    onOpenConnection: () =>
      console.log('%cSocket opened successfully', 'color:springgreen'),
    onError: (error) => console.error('Error message from server', error),
  })

  return webSocket
}

/**
 * Checks if there is an active `auth_token` on UAS side. If response is
 * successful then a websocket is open with the very same `auth
 */
const authTokenService = async (
  context: AuthMachineContext,
  event: AuthMachineEvent,
  self: AuthMachineSelf
) => {
  try {
    // event auth token is the UPGRADED auth token from scan
    // context auth token is going to be the auth token if a user
    // reloads the app with F5 or cancels a scan
    // This is a safety measure, but there is a 98% chance that the token
    // from `context` will always be fresh, because this service only starts
    // if the app enters the parallel AUTH_TOKEN state
    const authToken =
      event?.output?.authTokenInfo?.authToken ?? context?.authToken

    const { remainingTime: sessionRemainingTime } = context

    if (!authToken) {
      throw new TypeError(`No auth token found to start authTokenService`)
    }

    //Server has responded with 0 seconds, meaning there is no active session
    //for the sent auth token or the auth token was invalid
    if (sessionRemainingTime && sessionRemainingTime <= 0) {
      self.send({
        type: 'EXPIRED_SESSION',
        payload: {
          reason: 'No active session for given auth token on server',
          notifyUiWith: 'normalExpiredNotification',
          renderNotification: true,
        },
      })

      return Promise.reject({ error: 'No active session on server' })
    }

    // Sockets only opens if there is a session on server
    if (sessionRemainingTime && sessionRemainingTime > 0) {
      _openWebSocket(authToken, context, self)

      return Promise.resolve(sessionRemainingTime)
    }
  } catch (error) {
    // User has an invalid auth token or the auth token is expired
    if (
      (error as { response: { status: number } })?.response?.status ===
        API_STATUS.UNAUTHORIZED ||
      (error as { response: { status: number } })?.response?.status ===
        API_STATUS.FORBIDDEN
    ) {
      // session error
      self.send({
        type: 'EXPIRED_SESSION',
        payload: {
          reason: 'Session error, bad auth token or stale auth token',
          notifyUiWith: 'sessionErrorNotification',
          renderNotification: true,
        },
      })
    }
    writeToConsoleAndIssueAlert({ error })
  }

  return Promise.resolve(undefined)
}

/**
 * Fetches user account information
 */
const fetchUserDetails = async (
  authContext: AuthMachineContext,
  event?: AuthMachineEvent
): Promise<UserDetails | undefined> => {
  try {
    const response = await axios.get(
      API.userDetails,
      axiosConfig({
        authToken: event?.payload?.authToken ?? authContext?.authToken,
        signal: event?.payload?.abortController?.signal,
      })
    )

    const { status, data } = response as {
      status: number
      data: UserDetails
    }

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback && event?.payload?.successCallback(data)
      return verifiedPersonalDetails(data)
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.abortController?.abort()
    event?.payload?.finallyCallback?.()
  }

  return undefined
}

/**
 * **THIS SERVICE IS ONLY USED FOR DEV LOGIN AND E2E TESTING!!!**
 *
 * Do **NOT** use for production!!!
 *
 * Simulates "REDEEMING_MAGIC_TOKEN" for testing and for dev login.
 */
const devLogin = async (
  authContext: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    const { abortController, authToken, permissions } =
      event?.payload as unknown as {
        abortController: AbortController
        authToken: string
        permissions: string
      }

    const response = await axios.get(
      API.userDetails,
      axiosConfig({
        signal: abortController?.signal,
        authToken: authToken ?? authContext?.authToken,
      })
    )

    const { status, data } = response as {
      status: number
      data: UserDetails
    }

    if (status === API_STATUS.OK) {
      const authTokenInfo = {
        authToken,
        permissions,
        // In dev mode refresh token and auth token are the same
        refreshToken: authToken,
        remainingTime: 86_400,
      }

      event?.payload?.successCallback && event?.payload?.successCallback(data)
      return {
        userAccountInfo: verifiedPersonalDetails(data),
        authTokenInfo,
      }
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.abortController?.abort()
    event?.payload?.finallyCallback?.()
  }

  return undefined
}

/**
 * Redeems a magic link token for an auth token, if magic token is valid.
 * Otherwise a state transition won't happen and the user will not enter
 * authenticated state
 */
const redeemMagicToken = async (
  _: AuthMachineContext,
  event: AuthMachineEvent
): Promise<
  | {
      userAccountInfo: UserDetails
      authTokenInfo: AuthTokenInfo
      forecastParams: object | null
    }
  | undefined
> => {
  try {
    //TODO: Do the origin check here and throw an error
    if (!event?.payload?.magic_login_token) {
      throw new TypeError(
        `No magic login token  >> ${JSON.stringify(event?.payload)} <<`
      )
    }

    const response = await axios.post(
      API.loginMagicLinkNewTab,
      event?.payload?.magic_login_token,
      axiosConfig({
        signal: event?.payload?.abortController?.signal,
        rawBodyRequest: true,
      })
    )

    const { status, data } = response as {
      status: number
      data: MagicLoginResponse
    }

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback?.(data)

      track({
        event: AuthEvent.logged_in,
        properties: {
          object_id: 'email',
        },
      })

      return data
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.abortController?.abort()
    event?.payload?.finallyCallback?.()
  }

  return undefined
}

/**
 * Sends an email to the user in order for them to perform magic login
 */
const sendMagicLoginEmail = async (
  _: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    // TODO: Add email check for payload and output
    const { status } = await axios.post(
      API.sendEmailMagicLinkNewTab,
      {
        //Registration issues an internal event with `data`
        email: event?.payload?.email ?? event?.output?.payload?.email,
        forecastParams:
          event?.payload?.forecastUserData ??
          event?.output?.payload?.forecastUserData,
        uasHost: uas?.emailEnvironment,
      },
      // Timeout after 60 seconds
      axiosConfig({ timeout: 60_000 })
    )

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event?.payload)
      return event
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Terminates the `auth_token` on the server and logs the user out of the
 * application
 */
const logout = async (context: AuthMachineContext, event: AuthMachineEvent) => {
  try {
    const { allDevices } = event?.payload as {
      allDevices: boolean
    }

    const response = await axios.delete(
      allDevices
        ? `${API.logoutAllDevices}/${context?.authToken}`
        : `${API.logout}/${context?.authToken}`
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event?.payload)

      return event
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}
/**
 * Registers an user account with the UAS
 */
const registerUser = async (_: AuthMachineContext, event: AuthMachineEvent) => {
  // Needs to be outside the try catch block because the user can sign up on
  // an EXCEPTION FROM THE API!, and we need the forecast params they signed up with
  const {
    email,
    first_name,
    last_name,
    news,
    marketing,
    terms_and_conditions,
    referral_code,
    sex,
    birth_year,
    residency,
    payout_start_year,
    forecastParams,
  } = event?.payload as {
    email: string
    first_name: string
    last_name: string
    news: boolean
    marketing: boolean
    terms_and_conditions: number
    referral_code?: string
    sex: string
    birth_year: number
    //Needs to be ALPHA 3 code
    residency: string
    payout_start_year: number
    forecastParams: object
  }

  try {
    const { status } = await axios.post(
      API.register,
      {
        email,
        first_name,
        last_name,
        news,
        marketing,
        terms_and_conditions,
        referral_code,
        sex,
        birth_year,
        residency,
        dev_prod: uas.emailEnvironment,
        // if true sends an email to the user from the backend
        web_signup: isLite,
        payout_start_year,
        forecast_params: forecastParams,
      },
      axiosConfig()
    )

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback?.(event?.payload)

      track({
        event: AuthEvent.signup,
        properties: {
          object_id: 'no_referral_code',
          object_value: forecastParams,
          description: EVENT_DESC.referralCodeWithParams,
        },
      })

      return event
    }
  } catch (error) {
    type ApiError = {
      response: {
        data: {
          id: string
        }
      }
    }
    const errorId: string = (error as ApiError)?.response?.data?.id ?? ''

    // A user account is created even if the /create endpoint returns 400 with
    // error id UAS-REFERRAL-CODE-4 Every other error is a real error
    if (errorId === 'UAS-REFERRAL-CODE-4') {
      // UAS-REFERRAL-CODE-4 means that the user has signed up, but the referral
      // code was not found so the API returns 400, so it should not be an error
      // state and prevent the user from signing up
      event?.payload?.successCallback?.(errorId)

      // FIXME: Need to leave this here because of the marvel of engineering that the
      // backend is
      track({
        event: AuthEvent.signup,
        properties: {
          object_id: 'referral_code',
          object_value: forecastParams,
        },
      })
    } else {
      // Any other error
      writeToConsoleAndIssueAlert({
        error,
        failureCallback: event?.payload?.failureCallback,
      })
    }
    // Need to return the event because it contains the email data that the
    //SEND_MAGIC_LINK state needs to access
    return event
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Updates user's unverified account info
 */
const updateUserAccountInfo = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    const response = await axios.post(
      API.editUserDetails,
      {
        ...event?.payload,
      },
      axiosConfig({ authToken: context.authToken })
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event?.payload)

      return event
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Extends user's current session with pin
 */
const extendSession = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    if (!event?.payload?.pin) {
      throw new TypeError(
        `No pin found  >> ${JSON.stringify(event?.payload)} <<`
      )
    }

    const { pin } = event?.payload as {
      pin: string
    }

    const response = await axios.post(
      API.extendLoginSessionWithPin,
      // " " Is absolutely necessary because application/json
      // coverts the string 1234 to a number
      `"${pin}"`,
      axiosConfig({ rawBodyRequest: true, authToken: context?.authToken })
    )

    const { status, data } = response as {
      status: number
      data: AuthTokenInfo
    }

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback({ authTokenInfo: data })

      return { authTokenInfo: data }
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Creates a pin for the user's account
 */
const createNewPin = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    if (!event?.payload?.pin) {
      throw new TypeError(
        `No pin found  >> ${JSON.stringify(event?.payload)} <<`
      )
    }
    const { pin } = event?.payload as {
      pin: string
    }

    const response = await axios.post(
      API.savePin,
      {
        pin,
      },
      axiosConfig({ authToken: context?.authToken })
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback({ pin_set: true })
      return { pin_set: true }
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Changes the user's current pin to a new one.
 */
const changeCurrentPin = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    if (!event?.payload?.oldPin || !event?.payload?.newPin) {
      throw new TypeError(
        `No pin found  >> ${JSON.stringify(event?.payload)} <<`
      )
    }

    const { newPin, oldPin } = event?.payload as {
      newPin: string
      oldPin: string
    }

    const response = await axios.post(
      API.changePin,
      {
        pin: newPin,
      },
      axiosConfig({ userPin: oldPin, authToken: context?.authToken })
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event?.payload)

      return event
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Sends a pin reset email
 */
const sendPinResetEmail = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    const response = await axios.post(
      API.forgotPin,
      {
        dev_prod: uas?.emailEnvironment,
      },
      axiosConfig({ authToken: context.authToken })
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event?.payload)
      return event
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Resets the user's pin with an reset token
 */
const resetPin = async (_: AuthMachineContext, event: AuthMachineEvent) => {
  try {
    if (!event?.payload?.pin || !event?.payload?.reset_token) {
      throw new TypeError(
        `No pin or reset token found  >> ${JSON.stringify(event?.payload)} <<`
      )
    }

    const { pin, reset_token } = event?.payload as {
      pin: string
      reset_token: string
    }

    const response = await axios.post(
      API.resetPin,
      {
        pin,
        reset_token,
      },
      axiosConfig()
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event?.payload)
      return event
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Generates a referral code for the user, server side if user does not have a
 * referral code. Also allows the user to create a new referral with their
 * custom params, BUT only once
 */
const createReferralCode = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    if (
      !event?.payload?.referralCode ||
      typeof event?.payload?.referralCode !== 'string'
    ) {
      throw new TypeError(
        `No referral code provided or code not a string got >> ${event?.payload?.referralCode} <<`
      )
    }

    const { referralCode, refCodePrefix } = event.payload

    if (typeof refCodePrefix !== 'string') {
      throw new TypeError(
        `Referral code prefix needs to be a string, got >> ${refCodePrefix} <<`
      )
    }

    let requestBody = referralCode

    if (refCodePrefix) {
      requestBody = `${refCodePrefix}${referralCode}`
    }

    const response = await axios.post(
      API.createReferralCode,
      requestBody,
      axiosConfig({ rawBodyRequest: true, authToken: context.authToken })
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      const userDetails = {
        referralDetails: {
          // Keep the count, only update the referral code
          ...context?.user_details?.referralDetails,
          referral_code: referralCode,
        },
      } as UserDetails

      event?.payload?.successCallback?.(userDetails)

      return userDetails
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 *
 * Adds unverified phone number to the user's account. If the phone number is
 * not verified within certain amount of time, then the phone number will be
 * removed.
 *
 */
const addUnverifiedPhoneNumber = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    if (!event?.payload?.phone_number) {
      throw new TypeError(
        `No phone number found >> ${JSON.stringify(event?.payload)} <<`
      )
    }

    const { phone_number } = event?.payload as {
      phone_number: string
    }

    const response = await axios.post(
      API.addPhoneNumber,
      {
        phone_number,
      },
      axiosConfig({ authToken: context?.authToken })
    )

    const { status, data } = response as {
      status: number
      data: {
        verification_code_expiry_seconds: number
      }
    }

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback && event?.payload?.successCallback(data)
      return data
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Verifies the unverified phone number, with the SMS code sent to the
 * unverified phone number,
 */
const verifyPhoneNumber = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    if (!event?.payload?.verification_code) {
      throw new TypeError(
        `No verification code found >> ${JSON.stringify(event?.payload)} <<`
      )
    }

    const { verification_code } = event?.payload as {
      verification_code: string
    }

    const response = await axios.post(
      API.verifyPhoneNumber,
      {
        verification_code,
      },
      axiosConfig({ authToken: context?.authToken })
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback({
          phone_number: event?.payload?.phone_number,
        })
      // Returns the passed in verified phone number so the auth machine can
      // update the context with it
      return { phone_number: event?.payload?.phone_number }
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Schedules the user's account for closing
 */
const closeUserAccount = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    const { pin, closureFeedback } = event?.payload as {
      pin: string
      closureFeedback: string
    }

    const response = await axios.post(
      API.closeAccount,
      closureFeedback,
      axiosConfig({
        userPin: pin,
        rawBodyRequest: true,
        authToken: context?.authToken,
      })
    )

    const { status, data } = response as {
      status: number
      data: string
    }

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback({ closure_scheduled_time: data })
      return { closure_scheduled_time: data }
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Cancel the scheduled account closing action on the backend
 */
const cancelAccountClosing = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    const response = await axios.post(
      API.cancelClosingAccount,
      null,
      axiosConfig({ authToken: context?.authToken })
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback({ closure_scheduled_time: null })
      return { closure_scheduled_time: null }
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback && event?.payload?.finallyCallback()
  }

  return undefined
}

/**
 * Updates or adds a new payout account for a logged in user and returns the
 * event with it's payload to the `bankMachine`
 */
const updatePayoutDetails = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
): Promise<AuthMachineEvent | undefined> => {
  const { payoutDetails } = event?.payload as {
    payoutDetails: PayoutAccountDetails
  }

  try {
    if (!payoutDetails) {
      throw new TypeError(
        `Payout details key not found got >> ${JSON.stringify(
          payoutDetails
        )} <<`
      )
    }
    const { status } = await axios.post(
      API.updatePayoutAccount,
      {
        address: payoutDetails?.address,
        account: payoutDetails?.account,
      },
      axiosConfig({ authToken: context?.authToken })
    )
    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event.payload)

      return event
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  }

  return undefined
}

/**
 * Deletes a user's payout account if there is one
 */
const deletePayoutDetails = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
): Promise<AuthMachineEvent | undefined> => {
  try {
    const { status } = await axios.delete(API.deletePayoutAccount as string, {
      ...axiosConfig({ authToken: context?.authToken }),
    })

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback &&
        event?.payload?.successCallback(event.payload)
      return event
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  }

  return undefined
}

/**
 * Designed to be used with auth machine's face scan states
 *
 * Initializes the biometrics SDK and starts a face scan. Issues a callback if
 * the
 */
const startFaceScan = async (
  _: AuthMachineContext,
  event: AuthMachineEvent,
  self: AuthMachineSelf
) => {
  try {
    if (!event?.payload) {
      throw new Error(`Empty payload`)
    }

    const { email, authToken, scanType } = event.payload

    //ONLY POSSIBLE IN DEV MODE!!! Via window.sendDevEvent
    // TS is ignored for this line in order not to expose mock as a payload
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (event?.payload?.mockResponse) {
      // If mock response is passed in, API call is made and the /enroll mocked scan
      // data is returned
      return await mockFaceScan({
        scanType: scanType ?? 'AUTHENTICATION',
        authToken: authToken ?? _.authToken ?? '',
      })
    }

    if (!email || !scanType) {
      throw new Error(`Missing email or scan type`)
    }

    const isSDKInitialized = await _initSDK(
      facetec as {
        PublicFaceScanEncryptionKey: string
        deviceKeyIdentifier: string
        production: boolean
      }
    )

    // Starts a face scan only if sdk is init successfully
    if (isSDKInitialized) {
      const data = await _startFaceScan({
        email,
        authToken,
        scanType,
        // Issued only when enrollment is completed
        // in order to upgrade the auth token from enrollment
        onEnrollmentOnlyDone: (data) => {
          // Need to update the session storage with new refresh token
          sessionStorage.setItem(
            CONSTANTS.AUTH_MACHINE_KEY,
            JSON.stringify(data.authTokenInfo.refreshToken)
          )

          self.send({ type: 'FACE_ENROLL_COMPLETED', output: data })
        },
      })

      if (!data) {
        throw new Error('Fatal error, no data resolved from face scan!')
      }

      const { idScanCompleted, enrollmentCompleted } = data

      event?.payload?.successCallback?.({
        enrollmentCompleted,
        idScanCompleted,
      })

      return data
    }
    // Fatal error for the AuthMachine to transition to error state
    throw new Error('Unknown error occurred')
  } catch (exception) {
    // Ignore these SDK error codes
    const USER_CANCELLED_SCAN = 7
    const CONTEXT_SWITCH = 3

    if (exception) {
      if (
        (exception as { error?: { sessionResultStatusCode?: number } })?.error
      ) {
        const { error } = exception as {
          error: {
            sessionResultStatusCode?: number
          }
        }
        if (
          // Ignore if the user cancels the scan or if a context switch occurs,
          // since these are not considered actual scan errors and are errors based
          // on user's interaction with the scan SDK
          error?.sessionResultStatusCode !== USER_CANCELLED_SCAN &&
          error?.sessionResultStatusCode !== CONTEXT_SWITCH
        ) {
          // Error is thrown here as well
          writeToConsoleAndIssueAlert({
            error: error,
            failureCallback: event?.payload?.failureCallback,
          })
        }
      }
    }

    // Fatal error, if this is thrown
    throw new Error(
      `Error from startFaceScan >> ${JSON.stringify(exception)} <<`
    )
  }
}

/**
 * Refreshes the session with a refresh token
 */
const refreshSession = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    const refreshToken = safelyParseJSON(
      sessionStorage?.currentState as string
    ) as string

    // Check is made again to ensure there is still a refresh token in
    // sessionStorage if not, the session will be dropped
    if (!refreshToken) {
      throw new Error(
        'Could not get refresh token from session storage, session has been dropped'
      )
    }

    const response = await axios.post(
      API.refreshSession,
      refreshToken,
      axiosConfig({
        rawBodyRequest: true,
        signal: event?.payload?.abortController?.signal,
      })
    )

    const { status, data } = response as {
      status: number
      data: AuthTokenInfo
    }

    if (!data?.refreshToken) {
      throw new Error(
        'Server responded with 200 but did not return a new refresh token'
      )
    }

    sessionStorage?.setItem(
      CONSTANTS.AUTH_MACHINE_KEY,
      JSON?.stringify(data.refreshToken)
    )

    const userDetails = await fetchUserDetails(context, {
      // Does not do anything, just to add type safety since the
      // fetchUserDetails expects a auth machine event type
      type: 'FETCH_USER_ACCOUNT',
      payload: {
        authToken: data.authToken,
      },
    })

    if (!userDetails) {
      throw new Error(
        'Could not GET user account info with the /refresh, something went wrong'
      )
    }

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback?.({
        authTokenInfo: data,
        userAccountInfo: userDetails,
      })
      return { authTokenInfo: data, userAccountInfo: userDetails }
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.abortController?.abort()
    event?.payload?.finallyCallback?.()
  }

  return undefined
}

/**
 * Resend verification email, which can be only done in the Lite build of the app
 */
const resendVerificationEmailForLite = async (
  _: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    const response = await axios.post(
      API.resendVerificationEmail,
      event?.payload?.email ?? event?.output?.payload?.email,
      axiosConfig({
        rawBodyRequest: true,
      })
    )

    const { status } = response

    if (status === API_STATUS.OK) {
      event?.payload?.successCallback?.(event?.payload)
      return event
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback?.()
  }

  return undefined
}

/**
 * Verifies user's email by providing a token and returns their referral code
 * and referral details
 */
const liteAuth = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    const persistedAuthToken = localStorage.getItem(
      CONSTANTS.LITE_TOKEN
    ) as string

    if (!persistedAuthToken && !event?.payload?.verifyToken) {
      throw new Error(
        `Auth token not in storage and no verify token provided, provide auth token or verify token`
      )
    }

    const response = await axios.post(
      API.liteAuth,
      persistedAuthToken ? null : event?.payload?.verifyToken,
      axiosConfig({
        rawBodyRequest: true,
        signal: event?.payload?.abortController?.signal,
        authToken: context?.liteData?.liteAuthToken ?? persistedAuthToken,
      })
    )

    const { status, data } = response as {
      status: number
      data: {
        auth_token: string
        forecast_params: IncomeForecastRequestBody
        referral_info: UserReferralStats
      }
    }

    const pensionPlan =
      parseLiteParams(data.forecast_params) ?? ({} as LitePensionPlan)

    const liteData: LiteData = {
      referralDetails: parseLiteReferralData(data.referral_info),
      pensionPlan,
      liteAuthToken: data.auth_token,
    }

    if (status === API_STATUS.OK) {
      // Store value in local storage for now
      localStorage?.setItem(
        CONSTANTS.LITE_TOKEN,
        context?.liteData?.liteAuthToken ?? liteData?.liteAuthToken
      )

      track({
        event: AuthEvent.logged_in,
        properties: {
          object_id: 'email',
        },
      })

      event?.payload?.successCallback?.(liteData)
      return liteData
    }
  } catch (error) {
    // Clean storage if obsolete data is found, and it is not
    // aborted request
    if (!axios.isCancel(error)) {
      localStorage?.removeItem(CONSTANTS.LITE_TOKEN)
    }

    type ErrorType = {
      response: {
        data: {
          id: BackendErrorId
        }
      }
    }
    // Issue alert on every error except for UAS-AUTH-TOKEN-2 and UAS-AUTH-TOKEN-3 since it is
    // normal session expiry
    if (
      (error as ErrorType)?.response?.data?.id !== 'UAS-AUTH-TOKEN-2' &&
      (error as ErrorType)?.response?.data?.id !== 'UAS-AUTH-TOKEN-3'
    ) {
      writeToConsoleAndIssueAlert({
        error,
        failureCallback: event?.payload?.failureCallback,
      })
    }
  } finally {
    event?.payload?.finallyCallback?.()
    event?.payload?.abortController?.abort()
  }

  return undefined
}

/**
 * Gets user's referral information and binds all the info into one easy to use
 * referral details object
 */
const getReferralStats = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    const response = await axios.get(
      API.getReferralStats,
      axiosConfig({
        signal: event?.payload?.abortController?.signal,
        authToken: context.authToken,
      })
    )

    const { status, data } = response as {
      status: number
      data: UserReferralStats
    }

    if (!data) {
      throw new Error(`No data received from API`)
    }

    // Merge all referral info into one easy to use object
    const unifiedData: ReferralStat = data.reduce(
      (defaultCodeData, customCodeData) => {
        return {
          count_funded:
            defaultCodeData.count_funded + customCodeData.count_funded,
          count_paid_out:
            defaultCodeData.count_paid_out + customCodeData.count_paid_out,
          count_redeemed:
            defaultCodeData.count_redeemed + customCodeData.count_redeemed,
          // The default code will be used if no custom code is found
          referral_code: customCodeData.referral_code,
          editingLimitReached:
            data.length === CONSTANTS.REFERRAL_CODE_EDITING_LIMIT,
        }
      }
    )

    if (status === API_STATUS.OK) {
      const userDetails = {
        referralDetails: unifiedData,
      } as UserDetails

      event?.payload?.successCallback?.(userDetails)
      return userDetails
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback?.()
    event?.payload?.abortController?.abort()
  }

  return undefined
}

/**
 * Updates pension plan for an logged in user
 */
const updateLitePensionPlan = async (
  context: AuthMachineContext,
  event: AuthMachineEvent
) => {
  try {
    if (!event?.payload?.draftPensionPlan) {
      throw new Error(
        `Did not get payload for pension plan, check payload key >>draft<<`
      )
    }

    const {
      oneTimeContribution,
      retirementAge,
      contributionAge,
      sex,
      targetMonthlyIncome,
    } = event.payload.draftPensionPlan

    const { contributionAge: adjustedConAge, retirementAge: adjustedRetAge } =
      adjustAndConvertToAgeMonthString(retirementAge, contributionAge)

    const response = await axios.post(
      API.updateLitePlan,
      {
        sex,
        contributionAge: adjustedConAge,
        retirementAge: adjustedRetAge,
        targetMonthlyIncome,
        oneTimeContribution,
        // There is no monthly at least for now, until maybe a product change happens
        monthlyContribution: null,
      },
      axiosConfig({
        authToken: context?.liteData?.liteAuthToken,
      })
    )

    const { status } = response
    if (status === API_STATUS.OK) {
      event?.payload?.successCallback?.(event?.payload?.draftPensionPlan)
      return event?.payload?.draftPensionPlan
    }
  } catch (error) {
    writeToConsoleAndIssueAlert({
      error,
      failureCallback: event?.payload?.failureCallback,
    })
  } finally {
    event?.payload?.finallyCallback?.()
  }

  return undefined
}

export {
  addUnverifiedPhoneNumber,
  authTokenService,
  cancelAccountClosing,
  changeCurrentPin,
  closeUserAccount,
  createNewPin,
  createReferralCode,
  devLogin,
  extendSession,
  fetchUserDetails,
  logout,
  redeemMagicToken,
  registerUser,
  resetPin,
  sendMagicLoginEmail,
  sendPinResetEmail,
  updateUserAccountInfo,
  verifyPhoneNumber,
  deletePayoutDetails,
  updatePayoutDetails,
  startFaceScan,
  refreshSession,
  resendVerificationEmailForLite,
  liteAuth,
  getReferralStats,
  updateLitePensionPlan,
}
