import { CSSProperties, MouseEventHandler } from 'react'
import { TestID } from '../../../cypress/support/ui-component-ids'

interface IconProps {
  fileName: string
  className?: string
  alt?: string
  /**
   * @deprecated
   */
  fromWeb?: string
  onClick?: MouseEventHandler<HTMLImageElement>
  dataTestId?: TestID
  style?: CSSProperties
}

/**
 * Loads an icon from the provided `ICON` object or from a direct URL.
 */
const Icon = ({
  className,
  fileName,
  alt,
  fromWeb,
  onClick,
  style,
  dataTestId,
}: IconProps) => {
  return (
    <img
      style={style}
      onClick={onClick}
      className={className}
      alt={alt}
      //TODO: Eager loading for now
      // Eager for now
      loading="eager"
      src={fileName || fromWeb}
      data-testid={dataTestId}
    />
  )
}

export default Icon
