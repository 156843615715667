import { ReactNode, Suspense } from 'react'
import SentryErrorBoundary from './SentryErrorBoundary'
import { ANIMATION } from '../../constants/Animations'
import LottieAnimation from '../feedback/LottieAnimation'

type ErrorBoundaryAndSuspense = {
  children: ReactNode
  fallbackErrorComponent?: JSX.Element
  hideNavButton?: boolean
}

/**
 * Wraps a component in a `ErrorBoundary` and `Suspense` component
 * and displays a fallback UI if the component fails to render.
 *
 * While a component is suspended it renders a loading animation
 */
const ErrorBoundaryAndSuspense = ({
  children,
  fallbackErrorComponent,
  hideNavButton,
}: ErrorBoundaryAndSuspense) => {
  return (
    <SentryErrorBoundary
      fallbackErrorComponent={fallbackErrorComponent}
      hideNavButton={hideNavButton}
    >
      <Suspense
        fallback={
          <LottieAnimation
            animationName={ANIMATION?.splashLogo}
            autoplay
            loop
            className="splash-image"
            style={{
              scale: '0.3',
            }}
          />
        }
      >
        {children}
      </Suspense>
    </SentryErrorBoundary>
  )
}

export default ErrorBoundaryAndSuspense
