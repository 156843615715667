import Dropdown from './dropdown/Dropdown'
import countriesLocale from '../../constants/countries-locales.json'
import { getCountrySvgIcon } from '../../utils/TSUtilFunctions'

/**
 * Uses `countries-locales.json` to render countries with flag icons. The value is
 * the alpha3 code of the country and label is the name of the country
 */
const CountryDropdown = ({
  value,
  onChange,
  readOnly,
  label,
  className,
  optional,
}: {
  value: (typeof countriesLocale)[number] | string
  onChange?: (value: (typeof countriesLocale)[number] | string) => void
  readOnly?: boolean
  label?: string
  className?: string
  optional?: boolean
}) => {
  return (
    <Dropdown
      options={countriesLocale.map((country) => ({
        ...country,
        icon: getCountrySvgIcon(country.alpha2),
      }))}
      value={value}
      onChange={(value) => onChange?.(value)}
      itemKey={{ displayKey: 'name', valueOnChange: 'alpha3' }}
      searchBy={['name', 'alpha3', 'dial_code']}
      readOnly={readOnly}
      label={label}
      className={className}
      optional={optional}
    />
  )
}

export default CountryDropdown
