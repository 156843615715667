import { analyticsTrack } from 'MyTontineConfig'
import mixpanel from 'mixpanel-browser'
import { isLite } from '../config/lite'
import { writeToConsoleAndIssueAlert } from '../state-management/StateUtils'
import { debounce, detectDeviceType, throttle } from '../utils/TSUtilFunctions'
import { TrackRequest } from './Analytics.types'
import { CONSTANTS } from '../constants/ConstantValues'

// 2 minutes might be too fast, what if there is an error
// that affects a lot of users
const issueAlert = debounce(writeToConsoleAndIssueAlert, 120_000)

const _track = ({ event, properties }: TrackRequest) => {
  try {
    if (!event) {
      throw new TypeError(`Required event argument not provided`)
    }

    if (!properties) {
      throw new TypeError(`Required property: >>properties<< not provided`)
    }

    if (!properties.object_id) {
      throw new TypeError(
        `EVENT: ${event as string} Required missing >>object_id: ${properties.object_id} << 
        properties must contain >>object_id<<`
      )
    }

    const isAuth = Boolean(localStorage?.getItem(CONSTANTS.LITE_TOKEN))

    mixpanel.track(event as string, {
      ...properties,
      source: isLite ? 'website' : 'webapp',
      device: detectDeviceType(),
      auth: isAuth,
    })
  } catch (error) {
    // Tracking errors can happen, in order not to spam alerts
    // calls need to be throttled
    issueAlert({
      error,
      skipRethrow: true,
    })
  }
}

/**
 * Sends an API request to track user actions. The function is throttled to
 * 200ms by default.
 *
 * If no arguments are provided the function will not track anything.
 */
const track = throttle((trackProperties: TrackRequest) => {
  // We are only gonna track LITE for now, so we don't
  // spam data to the backend
  if (analyticsTrack && isLite) {
    // If no properties provided do not track
    if (!trackProperties) {
      return undefined
    }

    void _track({
      event: trackProperties.event,
      properties: trackProperties.properties,
    })
  }
}, 200)

export { track }
