import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Locize from 'i18next-locize-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import ChainedBackend from 'i18next-chained-backend'
import { locizePlugin, locizeEditorPlugin } from 'locize'
import { isLite } from './lite'
import LastUsed from 'locize-lastused'
import { environment } from 'MyTontineConfig'

const locizeConfig = {
  projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  apiKey: import.meta.env.VITE_LOCIZE_API_KEY,
  version: 'latest',
  referenceLng: 'en-US',
}

const localStorageCacheConfig = {
  // Expiration time in ms
  // Cache resets in 1 hour
  expirationTime:
    environment === 'production' ? 60 * 60 * 1_000 : 24 * 60 * 60 * 1_000,
}

if (environment === 'production') {
  // Pings locize every 3 minutes to communicate if there are keys
  // that are not being used in the app
  i18n.use(LastUsed)
}
// Locize editor only available in staging
if (environment !== 'production') {
  i18n
    .use(locizePlugin)
    // the default "incontext=true" query param does not work
    // it bugs out the editor and does not fetch the translations!!!
    .use(locizeEditorPlugin({ show: false, qsProp: 'edit' }))
}

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    //Need to add the language to initialize a starting language and namespace
    lng: 'en-US',
    // Default to lite ns if lite
    ns: isLite ? 'lite' : 'default',

    backend: {
      backends: [LocalStorageBackend, Locize],
      backendOptions: [localStorageCacheConfig, locizeConfig],
    },

    locizeLastUsed: { ...locizeConfig },

    react: {
      bindI18n: 'languageChanged editorSaved',
    },
    //Needed to support variable values like `11/11/2011`
    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
