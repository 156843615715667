import SocialShare from '../../components/media-and-icons/SocialShare'
import { useTranslate } from '../../hooks/useTranslate'
import ReferralCode from './ReferralCode'
import StatCards from './StatCards'
import style from '../../scss/layout/ReferralStats.module.scss'
import { tontineWebsiteOrigin } from 'MyTontineConfig'

/**
 * Renders referral stats with referral code CTAs
 */
const ReferralStats = ({
  referralCode,
  redeemCount,
  hideCount,
}: {
  referralCode?: string
  redeemCount?: number | '-'
  hideCount?: boolean
}) => {
  const t = useTranslate()

  return (
    <>
      {referralCode && (
        <>
          <ReferralCode
            referralCode={`${tontineWebsiteOrigin}/${referralCode}`}
            label={t('REF_CODE_DISPLAY_BOX')}
          />

          <div className={style['referral-stats__social-share']}>
            <SocialShare
              size={40}
              urlToShare={`${tontineWebsiteOrigin}/${referralCode}`}
              postTitle={t('SHARE_LINK_TITLE')}
              postContent={t('SHARE_LINK_CONTENTS', {
                referralLink: `${tontineWebsiteOrigin}/${referralCode}`,
              })}
              facebookHashtag={t('FACEBOOK_HASHTAG')}
              hashTags={[...t('TWITTER_HASHTAGS').split(',')]}
              twitterAccountsToFollow={[
                ...t('TWITTER_ACCOUNTS_TO_FOLLOW').split(','),
              ]}
              roundIcons
            />
          </div>
        </>
      )}

      {!hideCount && (
        <>
          <h3 className={style['referral-stats']}>{t('STATS_GROUP_TITLE')}</h3>

          <StatCards
            countTitle={t('STATS_REDEEM_COUNT')}
            valueTitle={t('STATS_REDEEM_VALUE')}
            redeemCount={redeemCount ?? '-'}
          />
        </>
      )}
    </>
  )
}

export default ReferralStats
