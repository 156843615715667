import { browserTracingIntegration, replayIntegration } from '@sentry/browser'

const environment = 'staging'

const envColor = '#f9de67'

const advancedDashboardURL = 'https://staging-advanced-dashboard.netlify.app/'
const tontineWebsiteOrigin = 'https://staging-tontine-com.netlify.app'
const mixpanelProxy = 'https://mixpanel.mytontine.com'

const sentry = {
  dsn: 'https://ddc29314123b499c8c746f51ff638a54@o990791.ingest.sentry.io/5962352',
  integrations: [browserTracingIntegration(), replayIntegration()],
  environment: 'Staging',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  enabled: true,
}

const uas = {
  baseUrl: 'https://staging-api.mytontine.com',
  baseUrlWSS: 'wss://staging-api.mytontine.com',
  emailEnvironment: 'staging',
}

const banking = {
  baseUrl: 'https://staging-api.mytontine.com',
}

const content = {
  baseUrl: 'https://hl9czw39.api.sanity.io/v2022-10-21/data/query/production',
}

const facetec = {
  PublicFaceScanEncryptionKey:
    '-----BEGIN PUBLIC KEY-----\n' +
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk\n' +
    'M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz\n' +
    'DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6\n' +
    'mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf\n' +
    'GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM\n' +
    'ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF\n' +
    '8QIDAQAB\n' +
    '-----END PUBLIC KEY-----',
  deviceKeyIdentifier: 'd4k2kPhkkYTwS4F426dPg4ZDCIFQVVFq',
  production: false,
}

const analyticsTrack = false

export {
  sentry,
  uas,
  facetec,
  environment,
  banking,
  envColor,
  content,
  advancedDashboardURL,
  tontineWebsiteOrigin,
  analyticsTrack,
  mixpanelProxy,
}
