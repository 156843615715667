import { useState } from 'react'
import { useTranslate } from '../../hooks/useTranslate'
import { IncomeStatsExtendedProps } from './DataDisplay.types'
import IncomeStatsExtended from './IncomeStatsExtended'
import MobileIncomeStatTip from './MobileIncomeStatTip'
import { useDeviceScreen } from '../../hooks/useDeviceScreen'

type RequiredProps = Pick<
  IncomeStatsExtendedProps,
  | 'contributionAmount'
  | 'incomeAmount'
  | 'incomePercentage'
  | 'isLoading'
  | 'incomeStartAge'
  | 'startingIncomeAtAge'
>

type CompareIncomeStatsProps = {
  plan1: RequiredProps
  plan2: RequiredProps
  hideP1Tooltip?: boolean
  hideP2Tooltip?: boolean
  currency?: 'USD' | 'EUR'
}

/**
 * Renders two IncomeStatsExtended components, one for each plan.
 */
const CompareIncomeStats = ({
  plan1,
  plan2,
  hideP1Tooltip,
  hideP2Tooltip,
  currency,
}: CompareIncomeStatsProps) => {
  const t = useTranslate()

  const [plan1Active, setPlan1Active] = useState(true)
  const [plan2Active, setPlan2Active] = useState(
    // Disable when init in mobile view
    !window?.matchMedia('(max-width: 900px)')?.matches
  )

  const { isMobileOrTablet } = useDeviceScreen((isMobileOrTablet) => {
    if (isMobileOrTablet) {
      setPlan1Active(true)
      setPlan2Active(false)
    } else {
      setPlan2Active(true)
    }
  })

  const sharedProps = {
    incomeLabel: t('FORECAST_PAGE.PAYOUTS_BY_100_LABEL'),
    contributionLabel: t('FORECAST_PAGE.TOTAL_CONTRIBUTION_LABEL'),
    currency: currency as 'USD' | 'EUR',
  }

  return (
    <>
      <section
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        {isMobileOrTablet && plan1 && plan2 && (
          <MobileIncomeStatTip
            tooltipText1={t('PLAN1_INPUT_GROUP')}
            tooltipText2={t('PLAN2_INPUT_GROUP')}
            onClickTip1={() => {
              setPlan2Active(false)
              setPlan1Active(true)
            }}
            onClickTip2={() => {
              setPlan1Active(false)
              setPlan2Active(true)
            }}
            tip1Active={Boolean(plan1Active && plan1)}
            tip2Active={Boolean(plan2Active && plan2)}
          />
        )}

        {plan1Active && plan1 && (
          <IncomeStatsExtended
            variant="blue-faint"
            staticToolTipText={t('PLAN1_INPUT_GROUP')}
            hideTooltip={hideP1Tooltip || isMobileOrTablet}
            {...sharedProps}
            {...plan1}
            bottomText={t('STARTING_INCOME_TEXT', {
              retirementAge: plan1.incomeStartAge ?? 0,
              startingIncomeAtAge: plan1.isLoading
                ? '-'
                : (plan1.startingIncomeAtAge ?? '-'),
            })}
          />
        )}
        {plan2Active && plan2 && (
          <IncomeStatsExtended
            variant="yellow-faint"
            staticToolTipText={t('PLAN2_INPUT_GROUP')}
            hideTooltip={hideP2Tooltip || isMobileOrTablet}
            {...sharedProps}
            {...plan2}
            bottomText={t('STARTING_INCOME_TEXT', {
              retirementAge: plan2.incomeStartAge ?? 0,
              startingIncomeAtAge: plan2.isLoading
                ? '-'
                : (plan2.startingIncomeAtAge ?? '-'),
            })}
          />
        )}
      </section>
    </>
  )
}

export default CompareIncomeStats
