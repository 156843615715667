import PropTypes from 'prop-types'
import { CONSTANTS } from '../../constants/ConstantValues'
import { useTranslate } from '../../hooks/useTranslate'
import { generateRange } from '../../utils/UtilFunctions'
import { getDetectedIpCountry } from '../../utils/TSUtilFunctions'
import IncomeScheduler from '../inputs/IncomeScheduler'
import AgeSliders from '../inputs/slider/AgeSliders'
import { ContributionSliders } from '../inputs/slider/ContributionSliders'
import { useLocalization } from '../../hooks/useLocalization'
import { UI_TEST_ID } from '../../constants/DataTestIDs'
import { useSlidersAdjustment } from './useSliderAdjustment'
import { environment } from 'MyTontineConfig'

/**
 * @param {Object} formsParams Object containing data for the input forms.
 * @param {Function} setNewFormData  Function for setting new data for the input
 * forms.
 * @param {boolean} retirementSliders  Renders only retirement sliders
 * @param {boolean} contributionSliders  Renders only contribution sliders
 * @param {string} contributionSlidersClassName Class name for the contribution
 * sliders.
 *
 * Renders IncomeScheduler (age-month accuracy) slider if specified and if
 * `ageThreshold` is present. Otherwise renders AgeSliders and
 * ContributionSliders (age accuracy)
 */
const TontinatorInputs = ({
  formData,
  setFormData,
  retirementSliders,
  contributionSliders,
  contributionSlidersClassName,
  disabledSliderTooltipText,
  ageMonthRetirementSlider,
  sliderSteps,
  setSliderSteps,
  ageThreshold,
  overrideDisable,
  hideCurrentAgeSlider,
  sliderVariant,
  forceDisableRetAgeDecrement,
  p2RetAgeSliderBoxTestId,
  p2RetAgeSliderIncrementTestId,
  p2RetAgeSliderDecrementTestId,
  p2OneTimeSliderBoxTestId,
  p2OneTimeIncrementButtonTestId,
  p2OneTimeDecrementButtonTestId,
  trackingActivityCurrentAgeSlider,
  trackingActivityRetirementSlider,
  trackActivityOneTimeContribution,
  trackCurrenAgeRangeActivity,
  trackRetAgeRangeActivity,
  trackRangeOneTime,
  skipComparePlanRangeAdjustment,
  hideTargetInput,
  trackActivityMonthlyTarget,
}) => {
  const t = useTranslate()
  const { formatAmount, isUSA, detectedCountry } = useLocalization()

  /**
   * Initializes the slider values based on supported params
   */
  const { tontinatorParams: supportedTontinatorParams } = detectedCountry
  // Overrides the backend max rules because of USA tax purposes and the slider
  // to be cut off
  if (isUSA) {
    supportedTontinatorParams.maxRetirementAge = 73
  }

  const {
    handleMonthly,
    handleOneTime,
    handleRetirementAge,
    handleCurrentAge,
    handleMonthlyTargetIncome,
  } = useSlidersAdjustment({
    formData,
    setFormData,
    minRetirementAge: supportedTontinatorParams?.minRetirementAge,
    maxRetirementAge: supportedTontinatorParams?.maxRetirementAge,
    params: supportedTontinatorParams,
  })

  const shouldDisabledUSA = disableRetirementSliderUSA({
    currentAge: formData?.contributionAge,
    maxRetirementAgeForCountry: supportedTontinatorParams?.maxRetirementAge,
  })

  /**
   * Controls the range of the sliders based on enabled and disabled ranges. If
   * only sliders would have `min` and `max` :(
   */
  const enabledSliderSteps = () => {
    if (shouldDisabledUSA) {
      return generateRange(0, 0)
    }

    if (
      formData?.contributionAge > supportedTontinatorParams?.minRetirementAge &&
      !skipComparePlanRangeAdjustment &&
      formData?.contributionAge < supportedTontinatorParams?.maxRetirementAge
    ) {
      return generateRange(
        formData?.contributionAge,
        supportedTontinatorParams?.maxRetirementAge
      )
    }

    return generateRange(
      supportedTontinatorParams?.minRetirementAge,
      supportedTontinatorParams?.maxRetirementAge
    )
  }

  return (
    <>
      {ageMonthRetirementSlider && ageThreshold && (
        <section className={`pension-plan__lower-section`}>
          <IncomeScheduler
            setRetirementData={setFormData}
            retirementData={formData}
            setSliderSteps={setSliderSteps}
            sliderSteps={sliderSteps}
            ageThresholds={ageThreshold}
          />
        </section>
      )}

      {retirementSliders && (
        <AgeSliders
          trackCurrenAgeRangeActivity={trackCurrenAgeRangeActivity}
          trackRetAgeRangeActivity={trackRetAgeRangeActivity}
          trackingActivityCurrentAgeSlider={trackingActivityCurrentAgeSlider}
          trackingActivityRetirementSlider={trackingActivityRetirementSlider}
          currentAgeBoxDataTestID={UI_TEST_ID.currentAgeSliderBox}
          retirementAgeBoxDataTestID={
            p2RetAgeSliderBoxTestId ?? UI_TEST_ID.retirementAgeSliderBox
          }
          retAgeIncrementDataTestID={
            p2RetAgeSliderIncrementTestId ??
            UI_TEST_ID.retirementAgeIncrementButton
          }
          retAgeDecrementDataTestID={
            p2RetAgeSliderDecrementTestId ??
            UI_TEST_ID.retirementAgeDecrementButton
          }
          currentAgeIncrementDataTestID={UI_TEST_ID.currentAgeIncrementButton}
          currentAgeDecrementDataTestID={UI_TEST_ID.currentAgeDecrementButton}
          variant={sliderVariant}
          hideCurrentAge={hideCurrentAgeSlider}
          className={contributionSlidersClassName}
          retirementAge={formData?.retirementAge}
          setRetirementAge={handleRetirementAge}
          //The reason retirement age and current age start the same range is
          //because we want the slider thumbs to aligned. The limits for
          //retirement age slider are handled with checkRetirementAgeIsBelowMin
          //function
          currentAgeSteps={generateRange(
            supportedTontinatorParams?.minCurrentAge,
            supportedTontinatorParams?.maxCurrentAge
          )}
          retirementAgeSteps={generateRange(
            supportedTontinatorParams?.minCurrentAge,
            supportedTontinatorParams?.maxCurrentAge
          )}
          retirementSliderLabel={t('DESIRED_GOAL.RETIREMENT_AGE')}
          currentAge={formData?.contributionAge}
          setCurrentAge={handleCurrentAge}
          currentAgeSliderLabel={t('INPUT_LABEL.CURRENT_AGE')}
          monthlyContribution={parseInt(formData?.monthlyContribution)}
          setMonthlyContribution={handleMonthly}
          // Onetime contribution and setter function.
          oneTimeContribution={parseInt(formData?.oneTimeContribution)}
          setOneTimeContribution={handleOneTime}
          disabledRetirementSlider={shouldDisabledUSA}
          disabledIncrementRetirementAge={
            formData?.retirementAge ===
              supportedTontinatorParams?.maxRetirementAge &&
            formData?.contributionAge <
              supportedTontinatorParams?.maxRetirementAge
          }
          disabledDecrementRetirementAge={
            // If force is passed in it will override the default behavior
            forceDisableRetAgeDecrement
              ? forceDisableRetAgeDecrement
              : formData?.retirementAge ===
                supportedTontinatorParams?.minRetirementAge
          }
          enabledRetirementSteps={enabledSliderSteps()}
        />
      )}

      {contributionSliders && (
        <ContributionSliders
          trackActivityMonthlyTarget={trackActivityMonthlyTarget}
          enabledStepsMonthly={
            formData?.contributionAge === formData?.retirementAge ||
            disableSliderIfUSA(formData?.countryOfResidence)
              ? [0, 0]
              : supportedTontinatorParams?.monthlyContribution
          }
          trackRangeOneTime={trackRangeOneTime}
          trackActivityOneTime={trackActivityOneTimeContribution}
          oneTimeBoxDataTestID={
            p2OneTimeSliderBoxTestId ?? UI_TEST_ID.oneTimeSliderBox
          }
          monthlyBoxDataTestID={UI_TEST_ID.monthlySliderBox}
          incOneTimeDataTestID={
            p2OneTimeIncrementButtonTestId ?? UI_TEST_ID.oneTimeIncrementButton
          }
          incMonthlyDataTestID={UI_TEST_ID.monthlyIncrementButton}
          decOneTimeDataTestID={
            p2OneTimeDecrementButtonTestId ?? UI_TEST_ID.oneTimeDecrementButton
          }
          decMonthlyDataTestID={UI_TEST_ID.monthlyDecrementButton}
          variant={sliderVariant}
          monthlySteps={supportedTontinatorParams?.monthlyContribution}
          oneTimeSteps={supportedTontinatorParams?.oneTimeContribution}
          oneTimeStepsIfRetired={
            supportedTontinatorParams?.oneTimeContributionIfRetired
          }
          className={contributionSlidersClassName}
          labelOneTime={t(`${isUSA ? '' : 'EU_'}CONTRIBUTION_SLIDER.ONE_TIME`)}
          labelMonthly={`${t('CONTRIBUTION_SLIDER.MONTHLY')} ${
            disableSliderIfUSA(formData?.countryOfResidence)
              ? CONSTANTS.MONTHLY_COMING_SOON
              : ''
          }`}
          // Pass in ages so the sliders can determine to be disabled.
          currentAge={parseInt(formData?.contributionAge)}
          retirementAge={parseInt(formData?.retirementAge)}
          formatter={(number) =>
            formatAmount({
              amount: number,
              residency:
                detectedCountry?.alpha3 ?? formData?.countryOfResidence,
              style: 'currency',
              digits: CONSTANTS.CURRENCY_DIGITS_FORMATTING,
              currency: detectedCountry?.currency,
            })?.formattedAmountWithSymbol
          }
          // Monthly contribution and setter function.
          monthlyContribution={(() => {
            if (overrideDisable) {
              return parseInt(formData?.monthlyContribution)
            }

            return formData?.contributionAge === formData?.retirementAge ||
              disableSliderIfUSA(formData?.countryOfResidence)
              ? 0
              : parseInt(formData?.monthlyContribution)
          })()}
          setMonthlyContribution={handleMonthly}
          // Onetime contribution and setter function.
          oneTimeContribution={parseInt(formData?.oneTimeContribution)}
          setOneTimeContribution={handleOneTime}
          disabledSliderTooltipText={disabledSliderTooltipText}
          //This disables the monthly slider if user is from USA, if they are
          //not rom USA then the slider is only disabled if current age matches
          //the retired age
          isRetired={
            overrideDisable
              ? false
              : disableSliderIfUSA(formData?.countryOfResidence) ||
                formData?.contributionAge === formData?.retirementAge
          }
          target={formData?.target}
          setTarget={(target) =>
            setFormData((prevFormData) => ({
              ...prevFormData,
              target,
              targetMonthlyIncome:
                target === 'target' ? CONSTANTS.USA_TARGET_MONTHLY : null,
              oneTimeContribution:
                target === 'target' ? null : CONSTANTS.USA_CD_DEPOSIT,
            }))
          }
          targetMonthlyLabel={t('TARGET_MONTHLY_INCOME_SLIDER')}
          hideTargetInput={
            hideTargetInput ??
            //FIXME: Temporary hack to hide target income for BOLD
            (formData?.strategy === 'BOL' && environment === 'production')
          }
          targetMonthlyIncomeSteps={
            supportedTontinatorParams?.targetMonthlyIncome
          }
          targetMonthlyIncome={parseInt(formData?.targetMonthlyIncome)}
          setMonthlyTargetIncome={handleMonthlyTargetIncome}
          targetMonthlyBoxDataTestID={UI_TEST_ID.targetMonthlyIncomeBox}
          targetMonthlyIncrementButtonDataTestID={
            UI_TEST_ID.targetMonthlyIncomeIncrementButton
          }
          targetMonthlyDecrementButtonDataTestID={
            UI_TEST_ID.targetMonthlyIncomeDecrementButton
          }
        />
      )}
    </>
  )
}

TontinatorInputs.propTypes = {
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  retirementSliders: PropTypes.bool,
  contributionSliders: PropTypes.bool,
  contributionSlidersClassName: PropTypes.string,
  disabledSliderTooltipText: PropTypes.string,
  ageMonthRetirementSlider: PropTypes.any,
  sliderSteps: PropTypes.array,
  setSliderSteps: PropTypes.func,
  isLoading: PropTypes.bool,
  ageThreshold: PropTypes.object,
  disabledRetirementAgeSlider: PropTypes.bool,
  inputsPrefix: PropTypes.string,
  overrideDisable: PropTypes.bool,
  hideCurrentAgeSlider: PropTypes.bool,
  sliderVariant: PropTypes.string,
  forceDisableRetAgeDecrement: PropTypes.bool,
  p2RetAgeSliderBoxTestId: PropTypes.string,
  p2RetAgeSliderIncrementTestId: PropTypes.string,
  p2RetAgeSliderDecrementTestId: PropTypes.string,
  p2OneTimeSliderBoxTestId: PropTypes.string,
  p2OneTimeIncrementButtonTestId: PropTypes.string,
  p2OneTimeDecrementButtonTestId: PropTypes.string,
  trackingActivityCurrentAgeSlider: PropTypes.object,
  trackingActivityRetirementSlider: PropTypes.object,
  trackActivityOneTimeContribution: PropTypes.object,
  trackCurrenAgeRangeActivity: PropTypes.object,
  trackRetAgeRangeActivity: PropTypes.object,
  trackRangeOneTime: PropTypes.object,
  skipComparePlanRangeAdjustment: PropTypes.bool,
  hideTargetInput: PropTypes.bool,
  trackActivityMonthlyTarget: PropTypes.object,
}

const disableRetirementSliderUSA = ({
  currentAge,
  maxRetirementAgeForCountry,
}) => currentAge >= maxRetirementAgeForCountry

/**
 * Prioritizes residency that is passed in by the user, otherwise uses the ip
 * detected country
 */
const disableSliderIfUSA = (chosenResidency) => {
  if (chosenResidency) {
    return chosenResidency === CONSTANTS.FALLBACK_COUNTRY_CODE
  }

  return getDetectedIpCountry() === CONSTANTS.FALLBACK_COUNTRY_CODE
}

export default TontinatorInputs
