const origins = [
  'http://localhost:3000',
  'http://localhost:8080',
  'https://staging-tontine-com.netlify.app',
  'https://tontine.com',
]
/**
 * List of allowed origins from tontine.com with trailing and without trailing slashes
 */
const allowedOrigins = [...origins, ...origins.map((origin) => `${origin}/`)]

export { allowedOrigins }
