import { useState, useEffect, useRef } from 'react'
import { generateApiError } from '../utils/UtilFunctions'
import { tontineBanking } from '../api/legacy/Banking'
import axios from 'axios'
import { useAccountService } from '../state-management/authentication/useAccountService'
import { CONSTANTS } from '../constants/ConstantValues'

/**
 * @legacy
 * Retrieves age threshold from th banking service. Age thresholds are used to
 * limit some inputs in the UI, so the user avoids making errors while using
 * some of the input elements
 */
export const useGetRetirementAgeThresholds = (params) => {
  const {
    context: { user_details, authToken },
    isAuthenticated,
  } = useAccountService()
  const abortController = useRef(new AbortController())

  const [ageThreshold, setAgeThreshold] = useState(undefined)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    setLoading(true)
    //Cancels the previous pending api request
    if (abortController.current) {
      abortController.current.abort()
    }
    //Assign new request source reference something like new request ID
    abortController.current = new AbortController()

    const handleSuccessfulAgeReading = (ageThresholdResponse) => {
      const ageThresholdProcessedResponse =
        processResponse(ageThresholdResponse)
      //Process the response before committing it to the state
      setAgeThreshold(ageThresholdProcessedResponse)
    }

    const handleFailedAgeReading = (error) => {
      //Skips setting the error state if it is an error of type cancelled from
      //axios
      if (!axios.isCancel(error)) {
        setError(generateApiError(error).translatedError)
      }
    }
    const debounceRequests = setTimeout(() => {
      if (isAuthenticated) {
        tontineBanking.readAgeThresholds(
          {
            ...params,
            residency: 'USA',
          },
          {
            onSuccess: handleSuccessfulAgeReading,
            onFailure: handleFailedAgeReading,
            onFinally: () => setLoading(false),
          },
          abortController.current.signal,
          authToken
        )
      }
    }, CONSTANTS.DEBOUNCE_SLIDERS_TIME)

    return () => {
      clearTimeout(debounceRequests)
      abortController.current.abort()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user_details?.date_of_birth,
    params?.current_age,
    params?.sex,
    params?.residency,
  ])

  /**
   * Modified the `age_threshold` response in order to make it easier for the
   * frontend to use
   */
  const processResponse = (apiResponse) => {
    const {
      for_simulation: {
        min_contribution,
        payout_start: { max, min, default: defaultLimits },
      },
    } = apiResponse

    return {
      contribution_start: {
        min_age: min_contribution?.age,
        min_year_month: min_contribution?.year_month,
        min_day: min_contribution?.day,
      },
      payout_start: {
        max_age: max?.age,
        max_year_month: max?.year_month,
        max_day: max?.day,
        min_age: min?.age,
        min_year_month: min?.year_month,
        min_day: min?.day,
        default_age: defaultLimits?.age,
        default_year_month: defaultLimits?.year_month,
        default_day: defaultLimits?.day,
      },
    }
  }

  return {
    ageThreshold,
    loading,
    error,
  }
}
